define('app/favorites',["jquery"], function ($) {
  var exports = {
    init: function () {
      $(".js-favorite").on("click", function (e) {
        if ($(e.currentTarget).hasClass("favorited")) {
          exports.removeFavorite(e.currentTarget);
        } else {
          exports.addFavorite(e.currentTarget);
        }
        e.preventDefault();
        e.stopImmediatePropagation();
      });
      return this;
    },

    addFavorite: function (link) {
      $.ajax({
        url: "/favorites/add/" + $(link).attr("data-article-id"),
        success: function (data) {
          $(link).addClass("favorited");
          $(link).closest('.heart').addClass("favorited");
        },
      });
    },

    removeFavorite: function (link) {
      $.ajax({
        url: "/favorites/remove/" + $(link).attr("data-article-id"),
        success: function (data) {
          $(link).removeClass("favorited");
          $(link).closest('.heart').removeClass("favorited");
        },
      });
    },
  };

  return exports;
});

