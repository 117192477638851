define('app/toggle-expand',["jquery", "app/effects"], function ($, Effects) {
  var $mainNavEl = $("#js-main-nav"),
    $thisEl = $(".js-toggle-expand");

  var exports = {
    el: $thisEl,

    init: function () {
      // For asynchronous loading, there may not be a $mainNavEl or $thisEl when
      // the page loads, so we need to re-capture those when we init.  (which happens
      // twice for async loads).
      $mainNavEl = $("#js-main-nav");
      $thisEl = exports.el = $(".js-toggle-expand");

      // clear any previous handlers
      $thisEl.off("click");
      $thisEl.on("click", exports.click);

      // Hide the mobile menu if clicked outside of it
      $(document).on("click touchend", function (event) {
        if (!$(event.target).closest("#js-main-header").length) {
          Effects.collapse($mainNavEl.closest(".js-can-expand"));
        }
      });

      return this;
    },

    click: function (e) {
      var captured = $(this);

      e.preventDefault();
      Effects.toggleExpand(captured.closest(".js-can-expand"));
    },

    catc: function () {
      return "cat";
    },
  };

  return exports;
});

