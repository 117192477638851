define('app/chart/chart-horizontal-bar',["jquery", "d3", "app/chart/chart-attr"], function ($, d3, ChartAttr) {
  var exports = {
    init: function ($element) {
      exports.loopThroughSVGs($element);

      return this;
    },

    loopThroughSVGs: function ($element) {
      var i;

      // Collect chart data attributes
      var chartAttr = new ChartAttr($element, {
        default: {
          margin: { top: 0, right: 25, bottom: 0, left: 8 },
          barPadding: 20,
          svgWidth: 272,
        },
      });

      // Set data variable
      var data = chartAttr.createDataObj();

      // Perform d3 calculations once
      var keys = d3
        .keys(chartAttr.dataObj[0])
        .filter(function (key) {
          return key !== chartAttr.attributeTitle;
        })
        .reverse();

      chartAttr.dataObj.forEach(function (d) {
        d.keys = keys.map(function (name) {
          return {
            name: name,
            value: +d[name],
            widthMultiplier: chartAttr.widthMultiplier(name),
          };
        });
      });

      chartAttr.$thisEl.removeClass("is-not-loaded");

      /* Execute Build Chart Function */
      for (i = 0; i < data.length; i++) {
        exports.buildChart(chartAttr, i);
      }

      return this;
    },

    buildChart: function (chartAttr, index) {
      // Select the sub chart once
      var subChartEl = $(chartAttr.chartIdSelector + "-" + index);

      chartAttr.updateSettings({
        default: {
          attributeColor: chartAttr.$thisEl.data("attributecolor"),
          barLabelColor: chartAttr.$thisEl.data("barlabelcolor"),
          barTextColor: chartAttr.$thisEl.data("bartextcolor"),
        },
      });

      // Custom chart logic
      // Set colors and on label divs
      var chartLabel = subChartEl
        .prev()
        .css({
          color: chartAttr.barTextColor,
          "background-color": chartAttr.attributeColor,
        })
        .addClass(chartAttr.size + "-height");

      // Set text in label divs
      chartLabel
        .children()
        .text(chartAttr.dataObj[index][chartAttr.attributeTitle]);

      // If there are images, add the background-image and class
      if (chartAttr.imagesObj.length > 0) {
        chartLabel
          .css({
            "background-image": "url(" + chartAttr.imagesObj[index] + ")",
          })
          .addClass("has-picture");
      }

      // Select correct data row based on iteration
      var data = chartAttr.dataObj[index].keys;

      // Width and Height calculations
      var width =
          chartAttr.svgWidth - chartAttr.margin.left - chartAttr.margin.right,
        height =
          50 * data.length - chartAttr.margin.top - chartAttr.margin.bottom;

      // Draw the chart
      var x = d3.scale.linear().range([0, width]);

      var y = d3.scale.ordinal().rangeRoundBands([height, 0], 0.1);

      var color = d3.scale.ordinal().range(chartAttr.colorsObj);

      var xAxis = d3.svg.axis().scale(x).orient("bottom");

      var yAxis = d3.svg.axis().scale(y).orient("left");

      var chart = d3
        .select(chartAttr.chartIdSelector + "-" + index)
        .attr("width", width + chartAttr.margin.left + chartAttr.margin.right)
        .attr("height", height + chartAttr.margin.top + chartAttr.margin.bottom)
        .classed("not-loaded", false)
        .append("g")
        .attr(
          "transform",
          "translate(" +
            chartAttr.margin.left +
            "," +
            chartAttr.margin.top +
            ")"
        );

      x.domain([0, 100]);
      y.domain(
        data.map(function (d) {
          return d.name;
        })
      );

      chart
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);

      chart.append("g").attr("class", "y axis").call(yAxis);

      chart
        .select(".y.axis")
        .selectAll(".tick")
        .select("text")
        .attr("x", 0)
        .attr("y", function (d) {
          var height = (y.rangeBand() - chartAttr.barPadding) / 2;

          return height;
        })
        .attr("dy", 5)
        .attr("fill", chartAttr.barLabelColor)
        .style("text-anchor", "start")
        .text(function (d) {
          return d;
        });

      var bar = chart
        .selectAll(".bar")
        .data(data)
        .enter()
        .append("g")
        .attr("transform", function (d) {
          return "translate(0," + y(d.name) + ")";
        });

      bar
        .append("rect")
        .attr("class", "bar")
        .attr("fill", function (d) {
          return color(d.name);
        })
        .attr("width", 0)
        .attr("x", 0)
        .attr("height", function (d) {
          var height = y.rangeBand() * d.widthMultiplier - chartAttr.barPadding;

          return height;
        })
        .transition()
        .attr("width", function (d) {
          return x(d.value);
        })
        .duration(chartAttr.animationDuration)
        .delay(chartAttr.animationDelay);

      bar
        .append("text")
        .attr("x", 5)
        .attr("y", function (d) {
          var yCenter =
            (y.rangeBand() * d.widthMultiplier - chartAttr.barPadding) / 2;

          return yCenter;
        })
        .attr("dy", "0.35em")
        .attr("class", "bar-label")
        .attr("fill", chartAttr.barLabelColor)
        .style("text-anchor", "start");

      bar
        .selectAll("text")
        .filter(function (d, i) {
          return exports.myIsNan(d.value);
        })
        .attr("x", 0)
        .text("N/A");

      bar
        .selectAll("text")
        .filter(function (d, i) {
          return !exports.myIsNan(d.value);
        })
        .text("0%")
        .transition()
        .duration(chartAttr.animationDuration)
        .tween("text", function (d) {
          var i = d3.interpolateRound(0, d.value);
          return function (t) {
            this.textContent = i(t) + "%";
          };
        })
        .attr("x", function (d) {
          return x(d.value) + 5;
        })
        .delay(chartAttr.animationDelay);

      return this;
    },

    myIsNan: function (o) {
      return o !== o;
    },
  };

  return exports;
});

