define('app/work',["jquery"], function ($) {
  var exports = {
    init: function () {
      $(
        "#work-page .case-study .feature-image a, #work-page .case-study .close"
      ).click(function () {
        exports.toggle(this);
        return false;
      });
    },
    toggle: function (el) {
      var study = $(el).parents(".case-study");

      if (study.hasClass("open")) {
        study.children(".content-body").slideUp(function () {
          study.removeClass("open").addClass("closed");
        });
      } else {
        study.children(".content-body").slideDown({
          start: function () {
            $(this).css({
              display: "block",
            });
          },
          done: function () {
            study.removeClass("closed").addClass("open");
          },
        });
      }
    },
  };

  return exports;
});

