define('app/tertiary-nav',[
  "jquery",
  "app/effects",
  "app/toggle-expand",
  "app/print",
  "app/favorites",
], function ($, Effects, toggle, print, favorites) {
  var $windowEl,
    $documentEl,
    backToReportsEl,
    popUpEl,
    popUpTitleEl,
    popUpSecondEl,
    tertiaryMenu,
    tertiaryNav,
    tertiaryPadding,
    tertiaryCurrentInfo,
    toReportsEl;

  var exports = {
    init: function () {
      if ($("body").hasClass("no-tertiary-nav")) {
        print.init(); // Daily still has print button
        return this;
      }
      exports.getHtml();
      return this;
    },

    build: function () {
      /* */
      $windowEl = $(window);
      $documentEl = $(document);
      backToReportsEl = $(".js-back-to-reports");
      popUpEl = $("#js-pop-up");
      popUpTitleEl = $(".pop-up-title");
      popUpSecondEl = $(".pop-up-second");
      tertiaryMenu = $("#js-tertiary-menu");
      tertiaryNav = $("#js-tertiary-nav");
      tertiaryPadding = $("#js-tertiary-padding");
      tertiaryCurrentInfo = $("#js-tertiary-current-info");
      toReportsEl = $(".js-to-report");

      toggle.init();
      print.init();
      favorites.init();
      exports.backToReports();
      exports.setFixedClass();
      exports.setMaxHeight();
      exports.titleToggleAll();
      exports.titleToggleUpdate();
      exports.toggleMenu();
      exports.toReport();
    },

    getHtml: function () {
      var id = $(".article-body").data("id");
      $.ajax({
        method: "GET",
        url: "/entry/tertiary-nav",
        data: {
          id: id,
        },
        dataType: "html",
        success: function (html) {
          $(".article-body").append(html);
          exports.build.apply(exports, []);
          $.event.trigger("tertiaryNavLoaded");
        },
      });
    },

    toggleMenu: function () {
      tertiaryCurrentInfo.on("click", function () {
        var captured = $(this);

        popUpEl.slideToggle("fast");
        Effects.toggleExpand(captured.closest(".js-can-expand"));
      });

      // Hide the menu if clicked outside of it
      $documentEl.on("click touchend", function (event) {
        if (!$(event.target).closest("#js-tertiary-nav").length) {
          popUpEl.slideUp("fast");
          Effects.collapse(tertiaryMenu);
        }
      });
    },

    setMaxHeight: function () {
      $windowEl
        .on("resize", function () {
          popUpEl.css("max-height", function () {
            return $windowEl.height() - 128;
          });
        })
        .trigger("resize");
    },

    setFixedClass: function () {
      $documentEl
        .on("scroll", function () {
          if (tertiaryNav.length !== 0) {
            if (
              tertiaryNav.offset().top + tertiaryNav.height() >=
              tertiaryPadding.offset().top + 43
            ) {
              tertiaryNav.removeClass("fixed");
            }
            if (
              $documentEl.scrollTop() + window.innerHeight <
              tertiaryPadding.offset().top + 68
            ) {
              tertiaryNav.addClass("fixed");
            }
          }
        })
        .trigger("scroll");

      return this;
    },

    toReport: function () {
      toReportsEl.on("click", function (e) {
        var capturedReport = "#" + $(this).data("report");

        e.preventDefault();
        Effects.toggleExpand($(capturedReport));
      });

      return this;
    },

    backToReports: function () {
      backToReportsEl.on("click", function (e) {
        e.preventDefault();
        Effects.toggleExpand($(".pop-up-main"));
      });

      return this;
    },

    titleToggleAll: function () {
      popUpTitleEl.on("click", function (e) {
        var captured = $(this);
        var selection;

        if (captured.hasClass("is-expanded")) {
          selection = captured.siblings(".js-can-expand");
        } else {
          selection = captured.siblings(".js-can-expand").not(".is-expanded");
        }

        captured.toggleClass("is-expanded");

        e.preventDefault();
        Effects.toggleExpand(selection);
      });

      return this;
    },

    titleToggleUpdate: function () {
      popUpSecondEl.on("click", function () {
        var captured = $(this);

        if (
          captured.children(".js-can-expand").filter(".is-expanded").length ===
          3
        ) {
          captured.children(".pop-up-title").addClass("is-expanded");
        } else {
          captured.children(".pop-up-title").removeClass("is-expanded");
        }
      });

      return this;
    },
  };

  return exports;
});

