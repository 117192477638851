define('app/css-loader',[], function () {
  var cssFilesToLoad = [
    /* For fonts.com to track the domains the fonts are being used with */
    "//fast.fonts.net/t/1.css?apiType=css&projectid=dfd45a27-5247-4ee9-8e4b-ca90a688df4a",
  ];

  var exports = {
    init: function () {
      for (var i = 0; i < cssFilesToLoad.length; i++) {
        this.load(cssFilesToLoad[i]);
      }
      return this;
    },

    load: function (file) {
      var cb = function () {
        var l = document.createElement("link");
        l.rel = "stylesheet";
        l.href = file;
        var h = document.getElementsByTagName("head")[0];
        h.parentNode.insertBefore(l, h);
      };
      var raf =
        requestAnimationFrame ||
        mozRequestAnimationFrame ||
        webkitRequestAnimationFrame ||
        msRequestAnimationFrame;
      if (raf) {
        raf(cb);
      } else {
        window.addEventListener("load", cb);
      }
    },
  };

  return exports;
});

