define('app/carousel',["jquery", "slick"], function ($, slick) {
  var exports = {
    init: function () {
      return this;
    },

    homePageInit: function () {
      exports.homePage();
      exports.testimonials();
    },

    staticPageInit: function () {
      exports.testimonials();

      return this;
    },

    articleInit: function () {
      exports.articles();

      return this;
    },

    eventsInit: function () {
      exports.eventsSidebarSlider();

      return this;
    },

    dailyInit: function () {
      exports.dailySidebarSlider();

      return this;
    },

    homePage: function () {
      var reportCarouselEl = $(".js-report-carousel"),
        isInitialized = false,
        resizeFrame;

      $(window)
        .on("resize", function () {
          var activeSlide = reportCarouselEl.children(".is-active");

          if (!isInitialized) {
            activeSlide.removeClass("is-initializing");
            isInitialized = true;
          }

          resizeFrame = window.requestAnimationFrame(function () {
            reportCarouselEl.height(activeSlide.height());
          });
        })
        .trigger("resize");

      $(".js-report-button").each(function () {
        $(this).on("click", function () {
          var slideIndex = $(this).data("slide"),
            slideEl = $("div[data-slide-index='" + slideIndex + "']"),
            thisEl = $(this);

          reportCarouselEl.height(slideEl.height());

          thisEl.siblings(".is-active").removeClass("is-active");
          thisEl.addClass("is-active");
          slideEl.siblings(".is-active").removeClass("is-active");
          slideEl.addClass("is-active");
        });
      });

      $(".homepage-carousel")
        .slick({
          accessibility: false,
          autoplay: true,
          infinite: false,
          pauseOnHover: false,
          autoplaySpeed: 3000,
          arrows: false,
          mobileFirst: true,
        })
        .on("beforeChange", function () {
          $(window).off(".carousel");
        })
        .on("afterChange", function (e, slick, cur) {
          $(window)
            .on("load.carousel scroll.carousel", function (el) {
              var scrolled = $(this).scrollTop() + $("header").outerHeight();

              $(".homepage-carousel .slick-active .slide-copy")
                .children()
                .each(function () {
                  var el = $(this);
                  var elOffset = el.offset().top + $("header").outerHeight();

                  if (scrolled > elOffset) {
                    el.css("visibility", "hidden");
                  } else {
                    el.css("visibility", "visible");
                  }
                });
            })
            .trigger("load");

          if (cur === slick.$slides.length - 1) {
            slick.setOption("autoplay", false, true);
          }
        });

      $(".homepage-daily-slider").slick({
        arrows: false,
        accessibility: false,
        mobileFirst: true,
        initialSlide: 29,
        infinite: false,

        responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: true,
              dots: false,
              initialSlide: 30,
              rtl: true,
              variableWidth: true,
              prevArrow: $(".prev"),
              nextArrow: $(".next"),
            },
          },
        ],
      });

      return this;
    },

    testimonials: function () {
      $(".testimonials-list").slick({
        arrows: false,
        dots: true,
      });

      return this;
    },

    articles: function () {
      $(".article-carousel").slick({
        arrows: true,
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: false,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
        ],
      });

      return this;
    },

    eventsSidebarSlider: function () {
      $(".event-page .sidebar .events").slick({
        arrows: false,
        dots: false,
        infinite: false,
        variableWidth: true,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: "unslick",
          },
        ],
      });
    },

    dailySidebarSlider: function () {
      $(".daily .sidebar .articles").slick({
        arrows: false,
        dots: false,
        infinite: false,
        variableWidth: true,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: "unslick",
          },
        ],
      });
    },
  };

  return exports;
});

