define('app/search',["jquery", "modernizr", "polyfill.requestanimationframe"], function (
  $,
  modernizr
) {
  var ajaxSearchResults = $("#js-ajax-search-results"),
    bodyEl = $("body"),
    htmlEl = $("html"),
    searchClose = $("#js-search-close"),
    searchInput = $("#js-search-input"),
    searchOpen = $(".js-search-open"),
    searchOverlay = $("#js-search-overlay");

  var exports = {
    init: function () {
      exports.searchClickClose();
      exports.searchEscClose();
      exports.searchOverlayOpen();
      exports.searchFocus();

      return this;
    },

    searchOverlayOpen: function () {
      searchOpen.on("click", function (e) {
        e.preventDefault();
        searchOverlay.addClass("open");
        htmlEl.addClass("scroll-lock");
      });

      return this;
    },

    searchClickClose: function () {
      searchClose.on("click", function (e) {
        searchOverlay.removeClass("open");
        htmlEl.removeClass("scroll-lock");
      });

      return this;
    },

    searchEscClose: function () {
      $(document).on("keydown", function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 27) {
          searchOverlay.removeClass("open");
          htmlEl.removeClass("scroll-lock");
        }
      });

      return this;
    },

    searchFocus: function () {
      $(document).on("keydown", function () {
        if (searchOverlay.hasClass("open")) {
          searchInput.focus();
        }
      });

      return this;
    },
  };

  return exports;
});

