define('app/chart/legend',["jquery", "d3"], function ($, d3) {
  var Legend = function ($element, data, options) {
    // Element attributes
    this.$chart = $element;
    this.data = data;
    this.options = {
      svg: {
        verticalPosition: "top",
        horizontalPosition: "right",
        itemXMargin: 30,
        itemYMargin: 3,
      },
      html: {},
      type: "html",
    };
    this.windowWidth = $(window).width();
    this.isMobile = this.windowWidth < 768;

    // Update Settings with initial values if options supplied
    if (options) {
      $.extend(this.options, options);
    }

    this.validateData();
    this.createLegend();
  };

  Legend.prototype.validateData = function () {
    for (var i = 0; i < this.data.length; i++) {
      if (!("label" in this.data[i]) || !("color" in this.data[i])) {
        throw "label and color are required for each data element";
      }
    }
  };

  Legend.prototype.createLegend = function (type) {
    if (this.options.type === "html") {
      this.createLegendHtml();
    } else {
      this.createLegendSvg();
    }
  };

  Legend.prototype.createLegendSvg = function () {
    var chart = d3.select(this.$chart.get(0));
    var legend = chart
      .append("g")
      .classed("legend", true)
      .selectAll(".legend")
      .data(this.data)
      .enter()
      .append("g")
      .classed("legend-item", true);

    legend
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", function (d) {
        return d.color;
      })
      .style("stroke", "black");

    legend
      .append("text")
      .attr("x", 23)
      .attr("y", 9)
      .attr("dy", ".35em")
      // .attr("font-size", "20px")
      .attr("text-anchor", "front")
      .classed("legend-text", true)
      .text(function (d) {
        return d.label;
      });

    // find the width of all legend-items

    var $items = this.$chart.find(".legend .legend-item");
    var width = 0;
    for (var i = 0; i < $items.length; i++) {
      width += Legend.boxWidth($items[i]);
    }
    width += this.options.svg.itemXMargin * ($items.length - 1);

    var chartWidth = this.$chart.width();
    var $item, bbox;

    if (width > chartWidth || this.isMobile) {
      // vertical layout
      var yCaret = 1;

      for (i = 0; i < $items.length; i++) {
        $item = $items.eq(i);
        bbox = $item.get(0).getBBox();
        $item.find("rect").attr("x", bbox.width - 18);
        $item.find("text").attr("x", 0);
        $item.attr(
          "transform",
          "translate(" + (chartWidth - (bbox.width + 1)) + "," + yCaret + ")"
        );
        yCaret += bbox.height + this.options.svg.itemYMargin;
      }
    } else {
      // horizontal layout
      var xCaret = 0;

      for (i = 0; i < $items.length; i++) {
        $item = $items.eq(i);
        var lWidth = Legend.boxWidth($item.get(0));
        $item.find("rect").attr("x", lWidth - 18);
        $item.find("text").attr("x", 0);
        $item.attr("transform", "translate(" + xCaret + ",0)");
        xCaret += lWidth + this.options.svg.itemXMargin;
      }
      this.$chart
        .find(".legend")
        .attr("transform", "translate(" + (chartWidth - (width + 1)) + ",1)");
    }
  };

  Legend.prototype.createLegendHtml = function () {
    var $chartWrap = this.$chart.closest(".chart-wrap");
    $chartWrap.find(".legend").remove();
    this.$chart.before('<ul class="legend"></ul>');

    var legend = d3
      .select($chartWrap.find(".legend").get(0))
      .selectAll(".legend-item")
      .data(this.data)
      .enter()
      .append("li")
      .classed("legend-item", true);

    legend
      .append("span")
      .attr("class", "legend-text")
      .text(function (d) {
        return d.label;
      });

    legend
      .append("div")
      .attr("class", "legend-box")
      .style("background-color", function (d, i) {
        return d.color;
      });
    // If legend wraps onto next line, we want to shift to a
    // tabular legend
    var items = $chartWrap.find(".legend .legend-item");
    var singleLine = true;
    if (items.length > 1) {
      var baseline = $(items[0]).offset().top;

      for (var i = 1; i < items.length; i++) {
        if ($(items[i]).offset().top !== baseline) {
          singleLine = false;
          $chartWrap.find(".legend").addClass("tabular");
          break;
        }
      }
    }
  };

  Legend.prototype.getHeight = function () {
    var legend = this.$chart.find(".legend");
    if (legend.length > 0) {
      if (this.options.type === "svg") {
        var box = legend.get(0).getBBox();
        return box.height;
      }
      return 0;
    }
    return 0;
  };

  Legend.boxWidth = function (element) {
    var box = element.getBBox();
    return box.width;
  };

  Legend.createData = function (labels, colors) {
    if (colors.length <= 0) {
      throw "No colors were provided to createData";
    }

    var data = [];
    for (var i = 0; i < labels.length; i++) {
      data.push({ label: labels[i], color: colors[i % colors.length] });
    }

    return data;
  };

  return Legend;
});

