require.config({
  /* Base Url is libs folder for simpler references */
  baseUrl: "/assets/js/lib",

  paths: {
    app: "/assets/js/app",
    jquery: "jquery",
    "jquery.transit": "jquery.transit",
    "jquery.scrollTo": "jquery.scrollTo",
    "jquery.easing": "jquery.easing",
    "jquery.placeholder": "jquery.html5-placeholder-shim",
    d3: "d3",
    slick: "slick",
    "viewport-units-buggyfill": "viewport-units-buggyfill",
    "polyfill.requestanimationframe": "polyfill.requestanimationframe",
  },

  shim: {
    "jquery.transit": ["jquery"],
    "jquery.scrollTo": ["jquery"],
    "jquery.easing": ["jquery"],
    "jquery.html5-placeholder-shim": ["jquery"],
  },

  waitSeconds: 15,
});

/* Grab the global modernizr object and wrap it in a define call */
define("modernizr", [],function () {
  "use strict";
  return window.Modernizr;
});

/* Main entry point */
require(["app/oxford"], function (Oxford) {
  return Oxford.init();
});

define("main", function(){});

