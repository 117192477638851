define('app/tooltips',["jquery"], function ($) {
  var $internalLinksEl;

  var exports = {
    init: function () {
      $internalLinksEl = $(".js-internal-link");
      exports.insertTooltip();

      return this;
    },

    insertTooltip: function () {
      if ($internalLinksEl.length) {
        $internalLinksEl.each(function () {
          var $thisEl = $(this),
            tooltipLinkText = $thisEl.html(),
            tooltipLinkHref = $thisEl.attr("href"),
            tooltipTitle = $thisEl.data("title"),
            tooltipImage = $thisEl.data("img"),
            tooltipIntro = $thisEl.data("intro"),
            tooltipHtml = "";

          tooltipHtml += '<a href="' + tooltipLinkHref + '" target="_blank">';
          tooltipHtml += '<span class="tooltip tooltip-effect">';
          tooltipHtml += '<span class="tooltip-item">';
          tooltipHtml += tooltipLinkText + "</span>";
          tooltipHtml += '<span class="tooltip-content clearfix">';
          tooltipHtml += '<img src="' + tooltipImage + '" />';
          tooltipHtml += '<span class="tooltip-text">';
          tooltipHtml += '<span class="tooltip-title">' + tooltipTitle;
          tooltipHtml += "</span>" + tooltipIntro;
          tooltipHtml += "</span></span></span></a>";

          $thisEl.replaceWith(tooltipHtml);
        });
      }

      return this;
    },
  };

  return exports;
});

