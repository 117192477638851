define('app/chart/chart-css',["jquery"], function ($) {
  var exports = {
    init: function ($element) {
      exports.showChart($element);

      return this;
    },

    showChart: function ($element) {
      $element.removeClass("is-not-loaded");

      return this;
    },
  };

  return exports;
});

