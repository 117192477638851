define('app/oxford',[
  "app/toggle-expand",
  "app/search",
  "app/favorites",
  "app/input-fields",
  "app/clicks",
  "app/css-loader",
  "app/work",
  "app/solutions",
  "app/articles-daily",
  "app/home",
], function (
  toggleExpand,
  search,
  favorites,
  inputFields,
  clicks,
  cssLoader,
  work,
  solutions,
  articlesDaily,
  home
) {
  var bodyId = document.body.id;
  var bodyClassList = document.body.classList;

  if (bodyId === "js-home-page") {
    require([
      "app/scroll-arrow",
      "app/carousel",
      "app/contact-form",
      "app/home",
    ], function (scrollArrow, carousel, contactForm, home) {
      scrollArrow.init();
      carousel.homePageInit();
      contactForm.init();
      home.init();
    });
  }

  if (bodyId === "js-article-page") {
    require([
      "app/tertiary-nav",
      "app/chart/charts",
      "app/carousel",
      "app/tooltips",
      "app/recommend",
      "app/contact-form",
    ], function (
      tertiaryNav,
      charts,
      carousel,
      tooltips,
      recommend,
      contactForm
    ) {
      tertiaryNav.init();
      charts.init();
      carousel.articleInit();
      carousel.dailySidebarSlider();
      tooltips.init();
      recommend.init();
      contactForm.init();
    });
  }

  if (bodyId === "js-static-page") {
    require([
      "app/carousel",
      "app/scroll-arrow",
      "app/contact-form",
      "app/module",
    ], function (carousel, scrollArrow, contactForm, module) {
      carousel.staticPageInit();
      scrollArrow.init();
      contactForm.init();
      module.init();
    });
  }

  if (bodyId === "js-sessions-page") {
    require(["app/carousel", "app/scroll-arrow"], function (
      carousel,
      scrollArrow
    ) {
      carousel.articleInit();
      scrollArrow.init();
    });
  }

  if (bodyId === "search-results") {
    require(["app/results"], function (results) {
      results.init();
    });
  }

  if (bodyId === "work-page") {
    require(["app/work", "app/carousel"], function (work, carousel) {
      carousel.staticPageInit();
      work.init();
    });
  }

  if (bodyId === "solutions-page") {
    require(["app/solutions", "app/quiz"], function (solutions) {
      solutions.init();
    });
  }

  if (bodyId === "events-page") {
    require(["app/carousel"], function (carousel) {
      carousel.eventsSidebarSlider();
    });
  }

  if (bodyClassList.contains("daily") && bodyClassList.contains("article")) {
    require(["app/articles-daily"], function (articlesDaily) {
      articlesDaily.init();
    });
  }

  /* Every Page */
  cssLoader.init();
  toggleExpand.init();
  search.init();
  favorites.init();
  inputFields.init();
  clicks.init();

  /* PolyFills */
  require(["viewport-units-buggyfill"], function (vhUnitsBuggyFill) {
    vhUnitsBuggyFill.init();
  });

  var exports = {
    init: function () {
      return this;
    },
  };

  return exports;
});

