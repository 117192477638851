define('app/chart/v2/chart-bubble',["jquery", "d3", "app/chart/v2/chart"], function ($, d3, Chart) {
  var BubbleChart = function ($element, data, opts) {
    this.options = {
      headingType: "metaHeading",
      smallCircleLimit: 20,
      smallCirclePadding: 20,
      orientation: "vertical" /* vertical or horizontal */,
      yLabelWidth: 140,

      default: {
        margin: { top: 20, right: 5, bottom: 60, left: 5 },
        heightMultiplier: 0.5,
        horizontalRowPadding: 10,
      },

      mobile: {
        margin: { top: 10, right: 5, bottom: 20, left: 5 },
        horizontalRowPadding: 20,
      },
    };

    // Call the super-constructor
    Chart.call(this, $element, data, opts);

    this.$element.addClass(this.options.orientation);

    return this;
  };

  // BubbleChart class extends the base Chart class.
  BubbleChart.prototype = Object.create(Chart.prototype);

  BubbleChart.prototype.draw = function () {
    if (this.options.orientation === "vertical") {
      this.drawVertical();
    } else {
      this.drawHorizontal();
    }
  };

  BubbleChart.prototype.drawVertical = function () {
    var chartData = this.chartData;
    var my = this;
    var margins = this.getMargins();

    // Make sure circles do not exceed height domain
    var maxCircleWidth = this.width / this.data.length,
      maxCircleHeight = this.height / this.data[0].columns.length,
      yUpperDomain =
        maxCircleWidth < maxCircleHeight ? maxCircleWidth : maxCircleHeight;

    this.x.domain(
      this.data.map(function (d) {
        return d.attribute;
      })
    );
    this.y.range([0, yUpperDomain]).domain([0, 100]);

    /* Draw yAxis */

    var chart = this.chart.select(".visual");
    chart.append("g").attr("class", "y axis").call(this.yAxis);

    chart
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0, 0)")
      .call(this.xAxis)
      .selectAll(".tick text")
      .call(this.wrapText, this.x.rangeBand() - 10);

    // In case the column text a the bottom is very long, we need
    // to adjust the chart height.  (Non-mobile only.)

    if (!this.isMobile()) {
      var bBox = chart.select(".x.axis").node().getBBox();
      var transformStr = this.$element.find(".visual").attr("transform");
      var args = transformStr
        .replace("translate(", "")
        .replace(")", "")
        .split(",");
      var heightShouldBe = bBox.height + parseFloat(args[1]);
      this.$element.attr("height", heightShouldBe);
    }

    // Create groups for circles
    var circleGroup = chart
      .selectAll(".circle-group")
      .data(this.data)
      .enter()
      .append("g")
      .attr("class", "circle-group")
      .attr("transform", function (d) {
        return "translate(" + my.x(d.attribute) + ",0)";
      })
      .on("mousedown", function (d) {
        my.$element.next().html(d.attribute);
      });

    var circleHeight = this.height / this.data[0].columns.length;
    // Draw circles
    circleGroup
      .selectAll("circle")
      .data(function (d) {
        return d.columns;
      })
      .enter()
      .append("circle")
      .attr("class", "slice")
      .attr("r", 0)
      .attr("transform", function (d, i) {
        return (
          "translate(" +
          my.x.rangeBand() / 2 +
          ", " +
          (circleHeight * (i + 1) - circleHeight / 2) +
          ")"
        );
      })
      .style("fill", function (d, i) {
        return d.color;
        // return my.colors[i % my.colors.length];
      });

    if (this.animate) {
      circleGroup
        .selectAll("circle")
        .transition()
        .attr("r", function (d) {
          return my.y(d.value) / 2;
        })
        .duration(my.animationDuration)
        .delay(my.animationDelay);
    } else {
      circleGroup.selectAll("circle").attr("r", function (d) {
        return my.y(d.value) / 2;
      });
    }

    var multiBubble = this.data[0].columns.length > 1;

    /* Add text labels to circles */
    circleGroup
      .selectAll("text")
      .data(function (d) {
        return d.columns;
      })
      .enter()
      .append("text")
      .attr("x", function (d) {
        var xOffset = 0;
        if (multiBubble && parseInt(d.value) < my.options.smallCircleLimit) {
          xOffset = my.y(d.value) / 2 + my.options.smallCirclePadding;
        }
        return my.x.rangeBand() / 2 + parseInt(xOffset);
      })
      .attr("y", function (d, i) {
        if (multiBubble) {
          return circleHeight * (i + 1) - circleHeight / 2;
        } else {
          return (-1 * margins.top) / 2;
        }
      })
      .attr("dy", multiBubble ? ".375em" : ".75em")
      .attr("class", "bar-label")
      .style("text-anchor", "middle");

    if (this.animate) {
      circleGroup
        .selectAll("text")
        .text(function (d) {
          return "0%";
        })
        .transition()
        .duration(my.animationDuration)
        .tween("text", function (d) {
          var i = d3.interpolateRound(0, d.value);
          return function (t) {
            this.textContent = i(t) + "%";
          };
        })
        .delay(my.animationDelay);
    } else {
      circleGroup.selectAll("text").text(function (d) {
        return d.value + "%";
      });
    }
  };

  BubbleChart.prototype.drawHorizontal = function () {
    var margins = this.getMargins();
    var width = this.width;
    var maxCircleDiameter = 200;
    var yLabelWidth = this.isMobile() ? width : this.options.yLabelWidth;
    var maxLabelHeight = this.calculateMaxYLabelHeight(yLabelWidth)
      .maxLabelHeight;
    var minHeight = this.height;
    var my = this;

    // Our maxCircleDiameter depends on how many columns are included

    var calculatedMaxCircleDiameter;
    var numColumns = this.data[0].columns.length;
    var numRows = this.data.length;

    if (numColumns === 1) {
      calculatedMaxCircleDiameter = width;
    } else {
      // divy up the width as 75% for bubbles, 25% for padding
      calculatedMaxCircleDiameter = (width * 0.75) / numColumns;
    }
    calculatedCircleDiameter = Math.min(
      maxCircleDiameter,
      calculatedMaxCircleDiameter
    );

    var rowSize, height, marginTop, marginBottom, horizontalRowPadding;
    if (this.isMobile()) {
      rowSize = calculatedCircleDiameter + maxLabelHeight;
      horizontalRowPadding = this.options.mobile.horizontalRowPadding;
    } else {
      rowSize = Math.max(maxLabelHeight, calculatedCircleDiameter);
      horizontalRowPadding = this.options.default.horizontalRowPadding;
    }
    height = numRows * rowSize + (numRows - 1) * horizontalRowPadding;

    this.height = height;
    d3.select(this.chartIdSelector).attr(
      "height",
      height + margins.top + margins.bottom + this.legendHeight
    );

    // set up axes

    var x = d3.scale
      .ordinal()
      .rangeRoundPoints(
        [this.isMobile() ? 0 : this.options.yLabelWidth, width],
        1
      );
    var y = d3.scale.ordinal().rangeRoundPoints([0, height], 1);
    y.domain(
      this.data.map(function (d) {
        return d.attribute;
      })
    );
    x.domain(
      this.data[0].columns.map(function (d, i) {
        return i;
      })
    );
    var circleScale = d3.scale
      .linear()
      .domain([0, 100])
      .range([0, calculatedCircleDiameter]);

    var xAxis = d3.svg.axis().scale(x).orient("bottom");
    var yAxis = d3.svg.axis().scale(y).orient("left");
    var chart = this.chart.select(".visual");

    var yAxisText = chart
      .append("g")
      .attr("class", "y axis")
      // .attr("transform", "translate(" + margins.left * -1 + ", 0)")
      .call(yAxis)
      .selectAll(".tick text")
      .call(this.wrapText, yLabelWidth, true)
      .style("text-anchor", this.isMobile() ? "middle" : "start");

    chart
      .selectAll(".tick line")
      .attr("x1", this.isMobile() ? margins.left : this.options.yLabelWidth)
      .attr("x2", width - margins.right);

    if (this.isMobile()) {
      yAxisText.attr(
        "transform",
        "translate(" + width / 2 + "," + rowSize / 2 + ")"
      );
    } else {
      yAxisText.attr("transform", "translate(0,0)");
    }

    // Create groups for circles
    var circleGroup = chart
      .selectAll(".circle-group")
      .data(this.data)
      .enter()
      .append("g")
      .attr("class", "circle-group")
      .attr("transform", function (d) {
        return "translate(0," + y(d.attribute) + ")";
      });

    // Draw circles
    circleGroup
      .selectAll("circle")
      .data(function (d) {
        return d.columns;
      })
      .enter()
      .append("circle")
      .attr("class", "slice")
      .attr("r", 0)
      .attr("transform", function (d, i) {
        return "translate(" + x(i) + ", 0)";
      })
      .style("fill", function (d, i) {
        return d.color;
        // return my.colors[i % my.colors.length];
      });

    if (this.animate) {
      circleGroup
        .selectAll("circle")
        .transition()
        .attr("r", function (d, i) {
          return circleScale(d.value) / 2;
        })
        .duration(my.animationDuration)
        .delay(my.animationDelay);
    } else {
      circleGroup.selectAll("circle").attr("r", function (d) {
        return circleScale(d.value) / 2;
      });
    }

    circleGroup
      .selectAll("text")
      .data(function (d) {
        return d.columns;
      })
      .enter()
      .append("text")
      .attr("transform", function (d, i) {
        return "translate(" + x(i) + ", 0)";
      })
      .attr("y", 0)
      .attr("dy", ".375em")
      .attr("class", "bar-label")
      .style("text-anchor", "middle");

    if (this.animate) {
      circleGroup
        .selectAll("text")
        .text(function (d) {
          return "0%";
        })
        .transition()
        .duration(my.animationDuration)
        .tween("text", function (d) {
          var i = d3.interpolateRound(0, d.value);
          return function (t) {
            this.textContent = i(t) + "%";
          };
        })
        .delay(my.animationDelay);
    } else {
      circleGroup.selectAll("text").text(function (d) {
        return d.value + "%";
      });
    }
  };

  return BubbleChart;
});

