define('app/results',["jquery"], function ($) {
  var exports = {
    /**
     * Things we need to be careful about: 1) User clicks filter before js is loaded
     * 2) User wants to click multiple boxes, so immediately changing URL interferes
     * with user interaction.
     */

    init: function () {
      $(".filter form input[type=checkbox]")
        .on("click", this.changeFilter)
        .prop("disabled", false);

      return this;
    },

    changeFilter: function () {
      var newFilters = [];
      $(".filter form input[type=checkbox]:checked").each(function () {
        newFilters.push($(this).val());
      });
      var queryParams = exports.getQueryParams(document.location.search),
        newSearch = "?q=" + encodeURIComponent(queryParams["q"]),
        filterParam = newFilters.join(",", newFilters);

      if (filterParam === "2,3,4" || filterParam === "") {
        document.location.search = newSearch;
      } else {
        document.location.search = newSearch + "&f=" + filterParam;
      }
    },

    getQueryParams: function (qs) {
      qs = qs.split("+").join(" ");

      var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

      tokens = re.exec(qs);
      while (tokens) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        tokens = re.exec(qs);
      }

      return params;
    },
  };

  return exports;
});

