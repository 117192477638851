define('app/input-fields',["jquery", "jquery.placeholder"], function ($) {
  var exports = {
    init: function () {
      exports.placeholderShim();

      return this;
    },

    placeholderShim: function () {
      if ($.placeholder) {
        $.placeholder.shim();
      }

      return this;
    },
  };

  return exports;
});

