define('app/chart/chart-attr',["jquery", "d3"], function ($, d3) {
  var ChartAttr = function ($element, object) {
    // Element attributes
    this.version = 1;
    this.$thisEl = $element;
    this.chartId = this.$thisEl[0].id;
    this.chartIdSelector = "#" + this.chartId;
    this.columnsObj = this.$thisEl.data("columns");
    this.attributeTitle = this.columnsObj[0].heading;
    this.rowsObj = this.$thisEl.data("rows");
    this.colorsObj = this.$thisEl.data("colors");
    this.metaHeadingsArray = this.getMetaHeadingsArray();
    this.headingsArray = this.getHeadingsArray();
    this.largestWidth = this.getLargestColumnWidth();
    this.svgWidth = this.$thisEl.parent().width();

    // Attributes on some Charts
    this.imagesObj = this.$thisEl.data("images");
    this.size = this.$thisEl.data("size");
    this.legendPerRow = 4;

    // Screen Attributes w/ defaults
    this.windowWidth = $(window).width();
    this.isMobile = this.windowWidth < 768;

    // Chart Options w/ defaults
    this.showGrid = this.$thisEl.data("showgrid") || 0;
    this.showColumnLegend = this.$thisEl.data("showcolumnlegend") || 0;
    this.showMetaToggle = this.$thisEl.data("showmetatoggle") || 0;
    this.headingType = 0;
    this.headingSort = false; // True to sort by headingType, but not insert toggle buttons
    this.dataLimit = 50;
    this.animationDuration = 1000;
    this.animationDelay = 100;
    this.margin = 0;
    this.barFixedHeight = 0;
    this.barPadding = 5;
    this.barGroupPadding = 0.1;
    this.heightMultiplier = 0.6;
    this.lineHeight = 17;

    // Data Object Creation
    this.dataObj = null;

    // Update Settings with initial values if object supplied
    if (object) {
      this.updateSettings(object);
    }
    // Make the instance available to the element
    $element.data("chartAttr", this);
  };

  ChartAttr.prototype.updateSettings = function (object) {
    $.extend(this, object.default);

    if (this.isMobile) {
      $.extend(this, object.mobile);
    }
  };

  ChartAttr.prototype.createDataObj = function (heading) {
    var columnLength = this.columnsObj.length,
      conditional = this.dataLoopCond(heading),
      dataObjFiltered = [],
      rowLength = this.rowsObj.length,
      columnHeading,
      i,
      x;

    for (i = 0; i < columnLength; i++) {
      if (conditional(i)) {
        columnHeading = this.columnsObj[i].heading.trim();

        // If it is not first column and has displayheading filled out
        if (i !== 0 && this.columnsObj[i].displayheading.trim()) {
          columnHeading = this.columnsObj[i].displayheading.trim();
        }

        for (x = 0; x < rowLength; x++) {
          if (!dataObjFiltered[x]) {
            dataObjFiltered[x] = {};
          }
          dataObjFiltered[x][columnHeading] = this.rowsObj[x][i].replace(
            /%/,
            ""
          );
        }
      }
    }

    // Add data object to constructor and run limit
    this.dataObj = dataObjFiltered;
    this.limitDataObj();

    return this.dataObj;
  };

  // For use in createDataObj - "for" loop logic
  ChartAttr.prototype.dataLoopCond = function (heading) {
    var self = this,
      sortOrToggle = this.showMetaToggle || this.headingSort,
      selector;

    // If chart groups data by the metaHeading values
    if (this.headingType === "metaHeading" && sortOrToggle) {
      // If metaHeading not specified, set it to first one in array
      if (
        (!heading || heading === "total") &&
        !this.columnsObj[1].metaheading.trim()
      ) {
        heading = this.columnsObj[1].heading.toLowerCase().trim();
        // If there is no metaheading, selector is heading
        selector = "heading";
      } else {
        if (!heading) {
          heading = this.columnsObj[1].metaheading.toLowerCase().trim();
        }
        selector = "metaheading";
      }

      // return conditional for "if" statement
      return function (i) {
        var colMetaHeading = self.columnsObj[i][selector].toLowerCase().trim(),
          isColumnShow = self.columnsObj[i].show === "1",
          isFirst = i === 0,
          isMetaHeading = colMetaHeading === heading;

        // console.log('i = ', i, (isFirst || (isColumnShow && isMetaHeading)), colMetaHeading, isColumnShow, isFirst, isMetaHeading);

        return isFirst || (isColumnShow && isMetaHeading);
      };

      // If chart groups data by the heading values
    } else if (this.headingType === "heading" && sortOrToggle) {
      // If metaHeading not specified, set it to first one in array
      if (!heading) {
        heading =
          this.columnsObj[1].displayheading.trim() ||
          this.columnsObj[1].heading.trim();
        heading = heading.toLowerCase();
      }

      // return conditional for "if" statement
      return function (i) {
        var colDisplayHeading = self.columnsObj[i].displayheading
            .toLowerCase()
            .trim(),
          colHeading = self.columnsObj[i].heading.toLowerCase().trim(),
          isColumnShow = self.columnsObj[i].show === "1",
          isDisplayHeading = colDisplayHeading === heading,
          isFirst = i === 0,
          isHeading = colHeading === heading,
          isEitherHeading = isHeading || isDisplayHeading;

        return isFirst || (isColumnShow && isEitherHeading);
      };

      // If chart doesn't group data and adds all the columns checked "show"
    } else {
      // return conditional for "if" statement
      return function (i) {
        var isColumnShow = self.columnsObj[i].show === "1",
          isFirst = i === 0;

        return isFirst || isColumnShow;
      };
    }
  };

  // Set a hard limit on rows (potentially for future use in CMS)
  ChartAttr.prototype.limitDataObj = function () {
    this.dataObj = this.dataObj.slice(0, this.dataLimit);

    return this.dataObj;
  };

  // Create Meta Headings Array based on Show column checkbox in CMS
  ChartAttr.prototype.getMetaHeadingsArray = function () {
    var columnLength = this.columnsObj.length,
      metaHeadings = [],
      columnMetaHeading,
      i;

    for (i = 0; i < columnLength; i++) {
      if (i !== 0 && this.columnsObj[i].show === "1") {
        if (this.columnsObj[i].metaheading === "") {
          metaHeadings.push("total");
        } else {
          columnMetaHeading = this.columnsObj[i].metaheading.toLowerCase();

          if (metaHeadings.indexOf(columnMetaHeading) === -1) {
            metaHeadings.push(columnMetaHeading);
          }
        }
      }
    }

    return metaHeadings;
  };

  ChartAttr.prototype.getFirstMetaheading = function () {
    metaheading = false;
    for (i = 0; i < this.columnsObj.length; i++) {
      if (this.columnsObj[i].show === "1" && this.columnsObj[i].metaheading) {
        metaheading = this.columnsObj[i].metaheading.toLowerCase();
        break;
      }
    }
    return metaheading;
  };

  // Create Headings Array based on Show column checkbox in CMS
  //
  // If a metaheading parameter is passed in, only the headings for that metaheading
  // will be returned.

  ChartAttr.prototype.getHeadingsArray = function (metaheading) {
    var columnLength = this.columnsObj.length,
      headings = [],
      heading,
      i;

    for (i = 0; i < columnLength; i++) {
      if (i !== 0 && this.columnsObj[i].show === "1") {
        if (metaheading) {
          if (
            this.columnsObj[i].metaheading.toLowerCase() !==
            metaheading.toLowerCase()
          ) {
            continue;
          }
        }
        heading =
          this.columnsObj[i].displayheading || this.columnsObj[i].heading;
        heading = heading.toLowerCase();

        if (headings.indexOf(heading) === -1) {
          headings.push(heading);
        }
      }
    }

    return headings;
  };

  // Create Meta Button unordered list and insert directly before chart
  ChartAttr.prototype.insertHeadingButtons = function (heading, callback) {
    if (this.showMetaToggle) {
      var self = this,
        i,
        headings,
        itemClass,
        toggleHtml;

      // Determine type of heading used for sorting
      if (this.headingType === "metaHeading") {
        headings = this.metaHeadingsArray;
        if (!heading || heading === "total") {
          heading = headings[0];
        }
      } else if (this.headingType === "heading") {
        headings = this.headingsArray;
        if (!heading) {
          heading = headings[0];
        }
      }

      // Remove existing buttons
      $("#js-heading-toggle-" + this.chartId).remove();

      // Create buttons HTML
      toggleHtml = '<ul class="button-list chart-toggle" ';
      toggleHtml += 'id="js-heading-toggle-' + this.chartId + '">';

      for (i = 0; i < headings.length; i++) {
        itemClass = "chart-toggle-item";
        if (headings[i] === heading) {
          itemClass += " active";
        }

        toggleHtml += '<li class="' + itemClass + '"';
        toggleHtml += ' data-heading="' + headings[i] + '">';
        toggleHtml += headings[i] + "</li>";
      }

      toggleHtml += "</ul>";

      // Insert buttons before chart
      this.$thisEl.before(toggleHtml);

      // Add click to toggle buttons
      this.$thisEl
        .prev()
        .children()
        .each(function () {
          $(this).on("click", function () {
            var heading = $(this).data("heading");

            callback(self.$thisEl, heading);
          });
        });
    }

    return this;
  };

  // Wrap text to specified width in SVG <text> element
  ChartAttr.prototype.wrapText = function (text, width, verticalCenter) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy + "em");

      while (words.length > 0) {
        word = words.pop();
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
      if (verticalCenter) {
        var tcount = text.selectAll("tspan").size();
        text.selectAll("tspan").attr("dy", function () {
          var ts = d3.select(this);
          var dy = parseFloat(ts.attr("dy"));
          var newDy = dy - (tcount - 1) * 0.5;

          return newDy + "em";
        });
      }
    });

    return this;
  };

  // Return Largest Column Width
  ChartAttr.prototype.getLargestColumnWidth = function () {
    var columnLength = this.columnsObj.length,
      largestWidth = 1,
      columnWidth,
      i;

    for (i = 0; i < columnLength; i++) {
      columnWidth = parseInt(this.columnsObj[i].width);

      if (columnWidth > largestWidth) {
        largestWidth = columnWidth;
      }
    }

    return largestWidth;
  };

  // Calculate Width Multiplier for each column
  ChartAttr.prototype.widthMultiplier = function (heading) {
    var columnWidth, index, indexes, width;

    indexes = $.map(this.columnsObj, function (obj, index) {
      if (obj.heading === heading || obj.displayheading === heading) {
        return index;
      }
    });

    index = indexes[0];

    columnWidth = parseInt(this.columnsObj[index].width);

    if (!columnWidth) {
      width = 1;
    } else {
      width = columnWidth;
    }

    return width / this.largestWidth;
  };

  ChartAttr.prototype.largestAxisLabel = function (drawnAxis) {
    var axisArray = drawnAxis[0],
      axisLength = axisArray.length,
      largestLabel = 1,
      axisChildNodesLength,
      i;

    for (i = 0; i < axisLength; i++) {
      axisChildNodesLength = parseInt(axisArray[i].childNodes.length);

      if (axisChildNodesLength > largestLabel) {
        largestLabel = axisChildNodesLength;
      }
    }

    return largestLabel;
  };

  ChartAttr.prototype.getDataKeys = function () {
    var keys = d3.keys(this.dataObj[0]).filter(function (key) {
      return key !== this.attributeTitle;
    });

    return keys;
  };

  ChartAttr.prototype.getLegendOffset = function (data) {
    if (this.isMobile) {
      // Adjust margin based on how many items in legend
      legendOffset = (data[0].keys.length - 1) * 25;
      // Update lenged to account for offset
      this.margin.top += legendOffset;
    } else {
      // Adjust margin based on how many items in legend
      legendOffset =
        Math.floor((data[0].keys.length - 1) / this.legendPerRow) * 25;
      // Update lenged to account for offset
      this.margin.top += legendOffset;
    }

    return legendOffset;
  };

  ChartAttr.prototype.notANumberTextHandler = function (value) {
    if (!isNaN(value)) {
      var i = d3.interpolateRound(0, value);
      return function (t) {
        this.textContent = i(t) + "%";
      };
    } else {
      return function (t) {
        this.textContent = "N/A";
      };
    }
  };

  // Cancel animations based on true variable
  ChartAttr.prototype.cancelAnimations = function (condition) {
    if (condition) {
      this.animationDuration = 0;
      this.animationDelay = 0;
    }
  };

  // Find middle of angle in SVG circle charts
  ChartAttr.prototype.midAngle = function (d) {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
  };

  // createColorObject()
  //
  // This function processes the color array and orders them
  // according to the metaheading.  This allows different headings
  // to have different colors rather than always using the same
  // colors (CWP-10)

  ChartAttr.prototype.createColorObject = function (metaheading) {
    var colorObj = [];
    var colorPalette = this.colorsObj.slice(); // make a copy of colorsObj
    var index;

    // If no metaheading is specified, use the first metaheading

    if (typeof metaheading === "undefined") {
      // We skip the first (attribute) column
      for (index = 1; index < this.columnsObj.length; index++) {
        if (
          typeof this.columnsObj[index].metaheading !== "undefined" &&
          this.columnsObj[index].show
        ) {
          metaheading = this.columnsObj[index].metaheading;
          break;
        }
      }
    }

    // Still not found?  Just give them the colorPalette.

    if (typeof metaheading === "undefined" || metaheading === null) {
      return colorPalette;
    }

    var colorIndices = this.getMetaHeadingIndices(metaheading);
    if (typeof colorIndices === "undefined") {
      return colorPalette;
    }

    for (index = 0; index < colorIndices.length; index++) {
      colorObj.push(colorPalette[colorIndices[index] % colorPalette.length]);
    }

    return colorObj;
  };

  ChartAttr.prototype.getMetaHeadingIndices = function (metaheading) {
    // First get metaheadings
    metaheading = metaheading.toLowerCase();
    var index;
    var metaheadings = [];
    var metaheadingsSeen = [];

    for (index = 1; index < this.columnsObj.length; index++) {
      if (this.columnsObj[index].show) {
        var mh = this.columnsObj[index].metaheading;
        if (!mh) {
          mh = this.columnsObj[index].heading;
        }
        if (mh) {
          mh = mh.toLowerCase();
          if (!(mh in metaheadingsSeen)) {
            metaheadings.push(mh);
            metaheadingsSeen[mh] = 0;
          }
          metaheadingsSeen[mh]++;
        }
      }
    }
    // Now we have the metaheading order in metaheadings, and the number of occurrences of
    // each heading in metaheadingsSeen

    // Now we want to create a positional array for each metaheading

    index = 0;
    var positions = [];
    for (var k = 0; k < metaheadings.length; k++) {
      positions[metaheadings[k]] = [];
      for (var j = 0; j < metaheadingsSeen[metaheadings[k]]; j++) {
        positions[metaheadings[k]].push(index++);
      }
    }
    return positions[metaheading];
  };

  return ChartAttr;
});

