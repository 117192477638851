define('app/chart/chart-pie',["jquery", "d3", "app/chart/chart-attr"], function ($, d3, ChartAttr) {
  var exports = {
    /* If resize is true, animations are turned off */

    init: function ($element, resize) {
      var heading = exports.getHeading($element);
      exports.addSVGs($element, resize, heading);

      return this;
    },

    addSVGs: function ($element, resize, metaHeading) {
      var i;

      // empty existing charts
      $element.find(".charts").empty();

      // Collect chart data attributes
      var chartAttr = new ChartAttr($element, {
        default: {
          headingType: "metaHeading",
          headingSort: true,
          animationDelay: 1600,
          margin: 10,
        },
      });

      chartAttr.$thisEl.removeClass("is-not-loaded");

      // if metatoggle is on and metaHeading is not set,
      // we need to set it to the first metaHeading.

      if (chartAttr.showMetaToggle && !metaHeading) {
        metaHeading = chartAttr.getFirstMetaheading();
      }

      if (chartAttr.showMetaToggle) {
        chartAttr.headingsArray = chartAttr.getHeadingsArray(metaHeading);
      }
      var labels = chartAttr.showMetaToggle
        ? chartAttr.headingsArray
        : chartAttr.metaHeadingsArray;

      // Execute Build Chart Function
      for (i = 0; i < labels.length; i++) {
        exports.buildChart(chartAttr, i, resize, metaHeading);
      }

      // Insert meta heading buttons if turned on
      chartAttr.insertHeadingButtons(metaHeading, function ($el, heading) {
        exports.metaToggleCallback.call(exports, $el, heading);
      });

      return this;
    },

    metaToggleCallback: function ($element, metaHeading) {
      exports.addSVGs($element, false, metaHeading);
    },

    getHeading: function ($element) {
      var heading = null;
      var chartAttr = $element.data("chartAttr");
      if (chartAttr && chartAttr.showMetaToggle) {
        heading = $("#js-heading-toggle-" + chartAttr.chartId)
          .find(".chart-toggle-item.active")
          .text();
      }
      return heading;
    },

    /* index is the index within the metaheading.  So, if metaheading is "gender",
     * index would be 0 or 1 (for male/female, two pies).  That's if metatoggle is on.
     */

    buildChart: function (chartAttr, index, resize, metaHeading) {
      chartAttr.$thisEl
        .find(".charts")
        .append(
          '<li class="chart-group"><svg id="' +
            chartAttr.chartId +
            "-" +
            index +
            '" width="100%" height="300"></svg><div class="chart-label"></div></li>'
        );

      var labels = chartAttr.showMetaToggle
        ? chartAttr.headingsArray
        : chartAttr.metaHeadingsArray;

      // Select the sub chart once
      var subChartEl = $(chartAttr.chartIdSelector + "-" + index);

      chartAttr.updateSettings({
        default: {
          svgWidth: $(chartAttr.chartIdSelector + "-" + index)
            .parent()
            .width(),
        },
      });

      // Custom chart logic
      // Set label text
      var chartLabel = subChartEl.next().text(labels[index]);

      // Cancel animations if resize is true
      chartAttr.cancelAnimations(resize);

      // Set data variable; this uses a metaheading regardless of metatoggle setting
      var data = chartAttr.createDataObj(
        chartAttr.showMetaToggle
          ? metaHeading
          : chartAttr.metaHeadingsArray[index]
      );

      // Default grid size and margins
      var width = chartAttr.svgWidth,
        height = chartAttr.svgWidth * 0.6,
        radius = height / 2 - chartAttr.margin,
        textWidth = width / 2 - radius - chartAttr.margin,
        translateCenter = width / 2 + "," + height / 2;

      var colorStartIndex = index * chartAttr.rowsObj.length;
      if (colorStartIndex >= chartAttr.colorsObj.length) {
        colorStartIndex = 0;
      }

      var valueTitle;

      for (var prop in data[0]) {
        if (prop !== chartAttr.attributeTitle) {
          if (chartAttr.showMetaToggle) {
            if (labels[index].toLowerCase() === prop.toLowerCase()) {
              valueTitle = prop;
            }
          } else {
            valueTitle = prop;
          }
        }
      }

      // Draw the chart
      var chart = d3
        .select(chartAttr.chartIdSelector + "-" + index)
        .html("") // Clear SVGs for redraw on resize
        .attr("width", width)
        .attr("height", height)
        .classed("not-loaded", false)
        .append("g")
        .attr("transform", "translate(" + translateCenter + ")");

      var pie = d3.layout
        .pie()
        .sort(null)
        .value(function (d) {
          return d[valueTitle];
        });

      var myColors = chartAttr.colorsObj.slice(
        colorStartIndex,
        colorStartIndex + chartAttr.rowsObj.length
      );
      var color = d3.scale.ordinal().range(myColors);

      var arc = d3.svg
        .arc()
        .outerRadius(radius * 0.85)
        .innerRadius(0);

      var percentArc = d3.svg
        .arc()
        .outerRadius(radius * 0.5)
        .innerRadius(radius * 0.5);

      var labelArc = d3.svg
        .arc()
        .outerRadius(radius * 0.75)
        .innerRadius(radius * 0.75);

      var outerArc = d3.svg
        .arc()
        .outerRadius(radius * 0.9)
        .innerRadius(radius * 0.9);

      var tweenPie = function (b) {
        b.innerRadius = 0;
        var i = d3.interpolate(
          {
            startAngle: 0,
            endAngle: 0,
          },
          b
        );
        return function (t) {
          return arc(i(t));
        };
      };

      /* ------- PIE SLICES ------- */

      var slices = chart
        .selectAll("g.slice")
        .data(pie(data))
        .enter()
        .append("g")
        .attr("class", "slice");

      if (resize) {
        slices
          .append("path")
          .style("fill", function (d) {
            return color(d.data[chartAttr.attributeTitle]);
          })
          .attr("d", arc);

        slices
          .append("text")
          .attr("transform", function (d) {
            return "translate(" + percentArc.centroid(d) + ")";
          })
          .attr("dy", ".35em")
          .style("text-anchor", "middle")
          .text(function (d) {
            return d.value + "%";
          })
          .attr("class", "slice-label");
      } else {
        slices
          .append("path")
          .style("fill", function (d) {
            return color(d.data[chartAttr.attributeTitle]);
          })
          .transition()
          .ease("cubic-in-out")
          .duration(chartAttr.animationDuration * 2)
          .attrTween("d", tweenPie);

        slices
          .append("text")
          .attr("transform", function (d) {
            return "translate(" + percentArc.centroid(d) + ")";
          })
          .attr("dy", ".35em")
          .style("text-anchor", "middle")
          .text("0%")
          .attr("class", "slice-label")
          .attr("opacity", 0)
          .transition()
          .attr("opacity", 1)
          .duration(chartAttr.animationDuration)
          .tween("text", function (d) {
            var inter = d3.interpolateRound(0, d.value);
            return function (t) {
              this.textContent = inter(t) + "%";
            };
          })
          .delay(chartAttr.animationDelay);
      }

      /* ------- TEXT LABELS ------- */

      chart.append("g").attr("class", "labels");

      var text = chart.select(".labels").selectAll("text").data(pie(data));

      var prevLabel = false;

      if (resize) {
        text
          .enter()
          .append("text")
          .attr("dy", ".35em")
          .attr("x", 0)
          .attr("y", 0)
          .attr("transform", function (d) {
            var pos = outerArc.centroid(d);
            pos[0] = radius * (chartAttr.midAngle(d) < Math.PI ? 1 : -1);
            // Make sure there's no overlap of labels
            if (prevLabel) {
              if (prevLabel[0] === pos[0]) {
                if (pos[0] > 0) {
                  pos[1] = Math.max(pos[1], prevLabel[1] + 12);
                } else {
                  pos[1] = Math.min(pos[1], prevLabel[1] - 12);
                }
              }
            }
            prevLabel = pos;
            return "translate(" + pos + ")";
          })
          .style("text-anchor", function (d) {
            return chartAttr.midAngle(d) < Math.PI ? "start" : "end";
          })
          .text(function (d, i) {
            return data[i][chartAttr.attributeTitle];
          })
          .call(chartAttr.wrapText, textWidth);
      } else {
        text
          .enter()
          .append("text")
          .attr("opacity", 0)
          .attr("dy", ".35em")
          .attr("x", 0)
          .attr("y", 0)
          .text(function (d, i) {
            return data[i][chartAttr.attributeTitle];
          })
          .call(chartAttr.wrapText, textWidth);

        text
          .transition()
          .duration(chartAttr.animationDuration)
          .attrTween("transform", function (d) {
            this.currentD = this.currentD || d;
            var interpolate = d3.interpolate(this.currentD, d);
            this.currentD = interpolate(0);
            return function (t) {
              var d2 = interpolate(t);
              var pos = outerArc.centroid(d2);
              pos[0] = radius * (chartAttr.midAngle(d2) < Math.PI ? 1 : -1);
              // Make sure there's no overlap of labels
              if (prevLabel) {
                if (prevLabel[0] === pos[0]) {
                  if (pos[0] > 0) {
                    pos[1] = Math.max(pos[1], prevLabel[1] + 12);
                  } else {
                    pos[1] = Math.min(pos[1], prevLabel[1] - 12);
                  }
                }
              }
              prevLabel = pos;
              return "translate(" + pos + ")";
            };
          })
          .styleTween("text-anchor", function (d) {
            this.currentD = this.currentD || d;
            var interpolate = d3.interpolate(this.currentD, d);
            this.currentD = interpolate(0);
            return function (t) {
              var d2 = interpolate(t);
              return chartAttr.midAngle(d2) < Math.PI ? "start" : "end";
            };
          })
          .attr("opacity", 1)
          .delay(chartAttr.animationDelay);
      }

      /* ------- SLICE TO TEXT POLYLINES ------- */

      chart.append("g").attr("class", "lines");

      var polyline = chart
        .select(".lines")
        .selectAll("polyline")
        .data(pie(data));

      if (resize) {
        polyline
          .enter()
          .append("polyline")
          .attr("points", function (d) {
            var pos = outerArc.centroid(d);
            pos[0] = radius * 0.95 * (chartAttr.midAngle(d) < Math.PI ? 1 : -1);
            // Make sure there's no overlap of labels
            if (prevLabel) {
              if (prevLabel[0] === pos[0]) {
                if (pos[0] > 0) {
                  pos[1] = Math.max(pos[1], prevLabel[1] + 12);
                } else {
                  pos[1] = Math.min(pos[1], prevLabel[1] - 12);
                }
              }
            }
            prevLabel = pos;
            var waypoint = outerArc.centroid(d);
            waypoint[1] = pos[1]; // stretch for overlap
            return [labelArc.centroid(d), waypoint, pos];
          });
      } else {
        polyline.enter().append("polyline").attr("opacity", 0);

        polyline
          .transition()
          .duration(chartAttr.animationDuration)
          .attr("opacity", 1)
          .attrTween("points", function (d) {
            this.currentD = this.currentD || d;
            var interpolate = d3.interpolate(this.currentD, d);
            this.currentD = interpolate(0);
            return function (t) {
              var d2 = interpolate(t);
              var pos = outerArc.centroid(d2);
              pos[0] =
                radius * 0.95 * (chartAttr.midAngle(d2) < Math.PI ? 1 : -1);
              // Make sure there's no overlap of labels
              if (prevLabel) {
                if (prevLabel[0] === pos[0]) {
                  if (pos[0] > 0) {
                    pos[1] = Math.max(pos[1], prevLabel[1] + 12);
                  } else {
                    pos[1] = Math.min(pos[1], prevLabel[1] - 12);
                  }
                }
              }
              prevLabel = pos;
              var waypoint = outerArc.centroid(d2);
              waypoint[1] = pos[1]; // stretch for overlap
              return [labelArc.centroid(d2), waypoint, pos];
            };
          })
          .delay(chartAttr.animationDelay);
      }

      polyline.exit().remove();

      /* ------- POINTS AT END OF POLYLINES ------- */

      chart.append("g").attr("class", "points");

      var points;

      if (resize) {
        points = chart
          .select(".points")
          .selectAll("point")
          .data(pie(data))
          .enter()
          .append("circle")
          .attr("r", 4)
          .attr("transform", function (d) {
            return "translate(" + labelArc.centroid(d) + ")";
          });
      } else {
        points = chart
          .select(".points")
          .selectAll("point")
          .data(pie(data))
          .enter()
          .append("circle")
          .attr("opacity", 0)
          .attr("r", 4)
          .attr("transform", function (d) {
            return "translate(" + labelArc.centroid(d) + ")";
          })
          .transition()
          .duration(chartAttr.animationDuration)
          .attr("opacity", 1)
          .delay(chartAttr.animationDelay);
      }

      return this;
    },
  };

  return exports;
});

