define('app/chart/chart-image',["jquery", "d3", "app/chart/chart-attr"], function ($, d3, ChartAttr) {
  var exports = {
    init: function ($element, resize) {
      exports.buildChart($element, resize);

      return this;
    },

    buildChart: function ($element) {
      var chartId = $element[0].id,
        $metaToggle = $("#js-meta-toggle-" + chartId);

      $element.removeClass("is-not-loaded");

      if ($metaToggle) {
        $metaToggle.children().each(function () {
          $(this).on("click", function () {
            var $thisEl = $(this),
              imgSrc = $thisEl.data("imgSrc");

            // Change button classes
            $thisEl.siblings().removeClass("active");
            $thisEl.addClass("active");

            // Show corresponding image
            $metaToggle.next(".js-chart-image-frame").attr("src", imgSrc);
          });
        });
      }

      return this;
    },
  };

  return exports;
});

