var questionNum = 1;
$(document).ready(function () {
  step(questionNum);

  $("#start-quiz").on("click", function (e) {
    e.preventDefault();
    $("#start-quiz").hide();
    $("#form-container").slideDown("slow", function () {
      if ($(window).width() > 1024) {
        $("#form-container").css("display", "flex");
      }
      $("#solutions-quiz .quiz-intro").css("background-color", "#0a1437");
      $("#solutions-quiz .quiz-intro").css("color", "white");
      $($(".previous")[0]).show();
    });
  });
});
collect();

var strategyOptions = [
  [
    "Do you wish you had a youth culture expert at your fingertips?",
    "Ideation,CassandraLive,CustomQualAndQuan",
  ],
  [
    "Are you interested in participating in an interactive discussion with an expert on youth culture and consumer behavior, or facilitating one for your team?",
    "TrendTreks,Ideation",
  ],
];

var membershipOptions = [
  "Do you have the tools you need to better engage with young consumers?",
  "Do you currently monitor market trends and their impact on your business?",
  "Can you differentiate between a passing fad and one that will have a lasting effect on your business?",
  "Do you understand your consumer’s attitudes and behaviors from a 360° view?",
  "Are you interested in a new way to make unexpected connections between youth trends, consumer behavior, and your brand?",
];

var researchOptions = [
  [
    "Do you want to be able to tap into a highly selective community of youth to test ideas, strategies, and concepts?",
    "Incubator",
  ],
  [
    "Do you want a new approach to facilitating focus groups that can provide insights and feedback from young consumers in real time?",
    "Caravan",
  ],
];
var dataCollection = [];
var answers = [];
var nonAnswers = [];
var warningFlag = false;
var sevenCounter = 0;
var globalStep = 0;
function step(stp) {
  globalStep = stp;
  if (stp === 1) {
    $("#question").text("Are you a current client?");
    $("#option1").text("Yes");
    $("#option2").text("No");
    $(".quiz-row").each(function (x) {
      if (x > 1) {
        $(this).hide();
      }
    });
  } else if (stp === 2) {
    $("#question").text(
      "Which products/services have you used in the past or are currently using? Please select all that apply."
    );
    $("#option1").text("Cassandra Membership (Report)");
    $("input[name='question1']").val("Cassandra Membership (Report)");
    $("#option2").text("Trend Trek");
    $("input[name='question2']").val("Trend Trek");
    $("#option3").text("Ideation Workshop");
    $("input[name='question3']").val("Ideation Workshop");
    $("#option4").text("Trendsetter Community");
    $("input[name='question4']").val("Trendsetter Community");
    $("#option5").text("Cassandra Live (In-person Presentation)");
    $("input[name='question5']").val("Cassandra Live (In-person Presentation)");
    $("#option6").text("Caravan (Omnibus Study)");
    $("input[name='question6']").val("Caravan (Omnibus Study)");
    $("#option7").text("Incubator");
    $("input[name='question7']").val("Incubator");
    $("#option8").text("Custom Quantitative Research");
    $("input[name='question8']").val("Custom Quantitative Research");
    $("#option9").text("Custom Qualitative Research");
    $("input[name='question9']").val("Custom Qualitative Research");
    $(".quiz-row").each(function (x) {
      if (x < 9) {
        $(this).show();
      }
    });
    // Step 3 has been removed from the quiz
  } else if (stp === 4) {
    $("#question").text("What industry (category) is your company part of?");
    $("#option1").text("Media & Entertainment");
    $("input[name='question1']").val("Media & Entertainment");
    $("#option2").text("Consumer Products");
    $("input[name='question2']").val("Consumer Products");
    $("#option3").text("Retail");
    $("input[name='question3']").val("Retail");
    $("#option4").text("Food and Beverage");
    $("input[name='question4']").val("Food and Beverage");
    $("#option5").text("Hospitality/Travel");
    $("input[name='question5']").val("Hospitality/Travel");
    $("#option6").text("Health & Wellness");
    $("input[name='question6']").val("Health & Wellness");
    $("#option7").text("Automotive");
    $("input[name='question7']").val("Automotive");
    $("#option8").text("Finance");
    $("input[name='question8']").val("Finance");
    $("#option9").text("Technology");
    $("input[name='question9']").val("Technology");
    $("#option10").text("Beer, Wine, Spirits");
    $("input[name='question10']").val("Beer, Wine, Spirits");
    $("#option11").text("Lifestyle");
    $("input[name='question11']").val("Lifestyle");
    $(".quiz-row").each(function (x) {
      $(this).show();
    });
  } else if (stp === 5) {
    $("#question").text("What is your role at your company?");
    $("#option1").text("Marketing");
    $("input[name='question1']").val("Marketing");
    $("#option2").text("Consumer Insights");
    $("input[name='question2']").val("Consumer Insights");
    $("#option3").text("Research");
    $("input[name='question3']").val("Research");
    $("#option4").text("Innovation");
    $("input[name='question4']").val("Innovation");
    $("#option5").text("Product Development");
    $("input[name='question5']").val("Product Development");
    $("#option6").text("Executive (CMO, CEO, President)");
    $("input[name='question6']").val("Executive (CMO, CEO, President)");
    $("#option7").text("Other");
    $("input[name='question7']").val("Other");
    $(".quiz-row").each(function (x) {
      if (x < 7) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
  } else if (stp === 6) {
    $("#question").text(
      "Which is the biggest challenge you’re trying to solve for your company (of the following)?"
    );
    $("#option1").text("Engaging and attracting young consumers");
    $("input[name='question1']").val("Engaging and attracting young consumers");
    $("#option2").text(
      "Evolving your brand and product ahead of the competition"
    );
    $("input[name='question2']").val(
      "Evolving your brand and product ahead of the competition"
    );
    $("#option3").text(
      "Uncovering and implementing actionable insights from research"
    );
    $("input[name='question3']").val(
      "Uncovering and implementing actionable insights from research"
    );
    $("#option4").text(
      "Innovation driven by quantified cultural market shifts"
    );
    $("input[name='question4']").val(
      "Innovation driven by quantified cultural market shifts"
    );
    $("#option5").text("Executing tactics based on insights");
    $("input[name='question5']").val("Executing tactics based on insights");
    $("#option6").text(
      "Determining which trends have relevancy for your business"
    );
    $("input[name='question6']").val(
      "Determining which trends have relevancy for your business"
    );
    $(".quiz-row").each(function (x) {
      if (x < 6) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
  } else if (stp === 7) {
    $("#question").text(membershipOptions.sample());
    $("#option1").text("Yes");
    $("input[name='question1']").val("Yes");
    $("#option2").text("No");
    $("input[name='question2']").val("No");
    $(".quiz-row").each(function (x) {
      if (x > 1) {
        $(this).hide();
      }
    });
  } else if (stp === 8) {
    $("#question").text(
      "How comfortable are you with turning cultural insights into brand strategy? Into commerce?"
    );
    $("#option1").text("Very uncomfortable");
    $("input[name='question1']").val("Membership,CassandraLive");
    $("#option2").text("Somewhat uncomfortable");
    $("input[name='question2']").val("Membership,CassandraLive");
    $("#option3").text("Somewhat comfortable");
    $("input[name='question3']").val("Membership");
    $("#option4").text("Very comfortable");
    $("input[name='question4']").val("Membership");
    $(".quiz-row").each(function (x) {
      if (x > 3) {
        $(this).hide();
      } else {
        $(this).show();
      }
    });
  } else if (stp === 9) {
    var stratQuestion = strategyOptions.sample();
    $("#question").text(stratQuestion[0][0]);
    $("#option1").text("Yes");
    $("input[name='question1']").val(stratQuestion[0][1]);
    $("#option2").text("No");
    $("input[name='question2']").val("No");
    $(".quiz-row").each(function (x) {
      if (x > 1) {
        $(this).hide();
      }
    });
  } else if (stp === 10) {
    var resQuestion = researchOptions.sample();
    $("#question").text(resQuestion[0][0]);
    $("#option1").text("Yes");
    $("input[name='question1']").val(resQuestion[0][1]);
    $("#option2").text("No");
    $("input[name='question2']").val("No");
    $(".quiz-row").each(function (x) {
      if (x > 1) {
        $(this).hide();
      }
    });
  }
}

function collect() {
  $("#quizForm").submit(function (e) {
    var form = this;
    e.preventDefault();
    var ans = $(this).serialize();
    if (ans !== "" && !(ans.includes("&") && globalStep > 8)) {
      form.reset();
      var pageNum = $("#page-num").text();
      $("#page-num").text(parseInt(pageNum) + 1);
      nextsteps(ans, questionNum);
    }
  });
}

function nextsteps(ans, stp) {
  if (stp === 1 && ans.split("=")[1] === "no") {
    answers.push("Membership");
    questionNum = 4;
    step(questionNum);
    $($(".counter")[0]).show();
  } else if (stp === 1 && ans.split("=")[1] === "yes") {
    questionNum = 2;
    step(questionNum);
    var totalNum = $("#total-page-num").text();
    $("#total-page-num").text("8");
    $($(".counter")[0]).show();
  } else if (stp === 2) {
    var tmpArr = ans.replace(/question\d=/g, "").split("&");
    nonAnswers = tmpArr;
    // Step 3 has been removed from the quiz
    if (nonAnswers.includes("Cassandra+Membership+(Report)")) {
      questionNum = 8;
      step(questionNum);
    } else {
      questionNum = 7;
      step(questionNum);
    }
  } else if (stp === 4) {
    dataCollection = ans.replace(/question\d=/g, "").split("&");
    questionNum = 5;
    step(questionNum);
  } else if (stp === 5) {
    dataCollection.concat(ans.replace(/question\d=/g, "").split("&"));
    questionNum = 6;
    step(questionNum);
  } else if (stp === 6) {
    dataCollection.concat(ans.replace(/question\d=/g, "").split("&"));
    if (nonAnswers.includes("Cassandra+Membership+(Report)")) {
      questionNum = 8;
      step(questionNum);
    } else {
      questionNum = 7;
      step(questionNum);
    }
  } else if (
    stp === 7 &&
    ans.split("=")[1] === "No" &&
    membershipOptions.length > 3
  ) {
    questionNum = 8;
    step(questionNum);
  } else if (stp === 7 && ans.split("=")[1] === "No") {
    questionNum = 7;
    step(questionNum);
  } else if (stp === 7 && ans.split("=")[1] === "Yes") {
    answers.push("Membership");
    questionNum = 8;
    step(questionNum);
  } else if (stp === 8) {
    answers.push(ans.replace(/question\d=/g, ""));
    questionNum = 9;
    step(questionNum);
  } else if (stp === 9 && strategyOptions.length > 0) {
    answers.push(ans.replace(/question\d=/g, ""));
    questionNum = 9;
    step(questionNum);
  } else if (stp === 9 && strategyOptions.length <= 0) {
    answers.push(ans.replace(/question\d=/g, ""));
    questionNum = 10;
    step(questionNum);
  } else if (stp === 10 && researchOptions.length > 0) {
    answers.push(ans.replace(/question\d=/g, ""));
    questionNum = 10;
    step(questionNum);
  } else if (stp === 10 && researchOptions.length <= 0) {
    calculateAnswers();
  }
}

function calculateAnswers() {
  var wordsCounter = {};
  results = [];
  var finalAnswers = answers.join("%2C").split("%2C");
  finalAnswers.forEach(function (word) {
    if (!wordsCounter.hasOwnProperty(word)) {
      results.push(word);
      wordsCounter[word] = 1;
    } else {
      results.push(word + "(" + (wordsCounter[word] += 1) + ")");
    }
  });
  delete wordsCounter.No;
  var num1 = Object.keys(wordsCounter).reduce(function (a, b) {
    return wordsCounter[a] > wordsCounter[b] ? a : b;
  });
  delete wordsCounter[num1];
  var num2 = Object.keys(wordsCounter).reduce(function (a, b) {
    return wordsCounter[a] > wordsCounter[b] ? a : b;
  });
  delete wordsCounter[num2];
  var num3 = "";
  if (!$.isEmptyObject(wordsCounter)) {
    num3 = Object.keys(wordsCounter).reduce(function (a, b) {
      return wordsCounter[a] > wordsCounter[b] ? a : b;
    });
  }

  if (num1 === "CassandraLive") {
    num1 = "Cassandra Live";
  } else if (num1 === "TrendsetterCommunity") {
    num1 = "Cassandra Trendsetter Community";
  } else if (num1 === "CustomQualAndQuan") {
    num1 = "Cassandra Custom";
  } else if (num1 === "Caravan") {
    num1 = "Caravan X Cassandra";
  } else if (num1 === "Incubator") {
    num1 = "Cassandra Incubator";
  } else if (num1 === "Ideation") {
    num1 = "Cassandra Ideation";
  } else if (num1 === "TrendTreks") {
    num1 = "Cassandra Trend Trek";
  }

  if (num2 === "CassandraLive") {
    num2 = "Cassandra Live";
  } else if (num2 === "TrendsetterCommunity") {
    num2 = "Cassandra Trendsetter Community";
  } else if (num2 === "CustomQualAndQuan") {
    num2 = "Cassandra Custom";
  } else if (num2 === "Caravan") {
    num2 = "Caravan X Cassandra";
  } else if (num2 === "Incubator") {
    num2 = "Cassandra Incubator";
  } else if (num2 === "Ideation") {
    num2 = "Cassandra Ideation";
  } else if (num2 === "TrendTreks") {
    num2 = "Cassandra Trend Trek";
  }

  if (num3 === "CassandraLive") {
    num3 = "Cassandra Live";
  } else if (num3 === "TrendsetterCommunity") {
    num3 = "Cassandra Trendsetter Community";
  } else if (num3 === "CustomQualAndQuan") {
    num3 = "Cassandra Custom";
  } else if (num3 === "Caravan") {
    num3 = "Caravan X Cassandra";
  } else if (num3 === "Incubator") {
    num3 = "Cassandra Incubator";
  } else if (num3 === "Ideation") {
    num3 = "Cassandra Ideation";
  } else if (num3 === "TrendTreks") {
    num3 = "Cassandra Trend Trek";
  }

  var id1 = "#" + num1.toLowerCase().split(" ").join("");
  var id2 = "#" + num2.toLowerCase().split(" ").join("");
  var id3 = "#" + num3.toLowerCase().split(" ").join("");

  $("#quizAnswers").show();
  if ($(window).width() > 1024) {
    $("#quizAnswers").css("display", "flex");
  }
  $("#form-container").hide();
  $(".quiz-intro").hide();
  $("#ans1").text(num1);
  $("#ans1").attr("href", id1);
  $("#ans2").text(num2);
  $("#ans2").attr("href", id2);
  $("#ans3").text(num3);
  $("#ans3").attr("href", id3);

  $("#ans1").click(function (e) {
    e.preventDefault();
    document.querySelector(id1).scrollIntoView({ behavior: "smooth" });
  });

  $("#ans2").click(function (e) {
    e.preventDefault();
    document.querySelector(id2).scrollIntoView({ behavior: "smooth" });
  });

  $("#ans3").click(function (e) {
    e.preventDefault();
    document.querySelector(id3).scrollIntoView({ behavior: "smooth" });
  });
}

Array.prototype.sample = function () {
  return this.splice(Math.floor(Math.random() * this.length), 1);
};

define("app/quiz", function(){});

