define('app/chart/v2/chart-data',[], function () {
  var ChartData = function (data, options) {
    // Element attributes
    this.version = 2;
    this.data = data;
    this.columnsObj = data.columns;
    this.attributeTitle = this.columnsObj[0].heading;
    this.rowsObj = data.rows;
    this.colorsObj = data.colors;
    this.metaHeadingsArray = this.getMetaHeadingsArray();
    this.headingsArray = this.getHeadingsArray();
    this.largestWidth = this.getLargestColumnWidth();

    // Attributes on some Charts
    this.imagesObj = data.images;
    this.size = data.size;
    this.legendPerRow = 4;

    // options w/ defaults
    this.options = {
      showGrid: false,
      showColumnLegend: false,
      showMetaToggle: false,
      headingType: "heading", // heading or metaHeading
      useSameColorsPerMeta: false, // Default shifts color palette per meta toggle
      removeEmptyDataRows: true, // Don't include rows that have no numeric data
    };

    $.extend(this.options, data.options);
    $.extend(this.options, options);

    this.headingType = this.options.headingType;
    this.showMetaToggle = this.options.showMetaToggle;

    // Data Object Creation
    this.dataObj = null;

    // Make the instance available to the element
    // $element.data('chartData', this);

    // this.exportJSON();
  };

  // ChartData.prototype.exportJSON = function() {
  // 	var myObj = {
  // 		columns: this.$thisEl.data("columns"),
  // 		rows: this.$thisEl.data("rows"),
  // 		colors: this.$thisEl.data("colors")
  // 	};
  // 	console.log(JSON.stringify(myObj));
  // };

  // The data object is an array; each entry contains:
  //   attribute: (row attribute)
  //   columns: array; each entry contains:
  //     color
  //     label
  //     value

  ChartData.prototype.createDataObj = function (heading) {
    if (this.showMetaToggle && !heading) {
      heading = this.getFirstMetaheading();
      // console.log('getFirstMetaheading()', heading);
      if (!heading) {
        this.headingType = "heading";
      }
    }
    // console.log('headingType', this.headingType);
    // console.log('columnsObj', this.columnsObj);
    // console.log('heading', heading);
    var columnLength = this.columnsObj.length,
      conditional = this.dataLoopCond(heading),
      dataObjFiltered = [],
      rowLength = this.rowsObj.length,
      columnHeading,
      i,
      x;

    for (i = 0; i < columnLength; i++) {
      // console.log('i = ', i, 'conditional(i)', conditional(i));
      if (i === 0 || conditional(i)) {
        // console.log('adding ', i, 'column');
        // console.log('this.headingType === "metaheading"', this.headingType === "metaheading");
        // console.log('!this.columnsObj[i].metaheading', !this.columnsObj[i].metaheading);
        // console.log('i !== 0', i !== 0);

        if (
          this.showMetaToggle &&
          this.headingType === "metaHeading" &&
          !this.columnsObj[i].metaheading &&
          i !== 0
        ) {
          // console.log('showMetaToggle', this.showMetaToggle);
          // console.log('columnsObj[i]', this.columnsObj[i]);
          // console.log('adding Total');
          columnHeading = "Total";
        } else {
          columnHeading = this.columnsObj[i].heading;
        }

        // If it is not first column and has displayheading filled out
        if (i !== 0 && this.columnsObj[i].displayheading) {
          columnHeading = this.columnsObj[i].displayheading;
        }

        for (x = 0; x < rowLength; x++) {
          if (!dataObjFiltered[x]) {
            dataObjFiltered[x] = {};
          }
          if (i === 0) {
            dataObjFiltered[x].attribute = this.rowsObj[x][i].replace(/%/, "");
            dataObjFiltered[x].columns = [];
          } else {
            dataObjFiltered[x].columns.push({
              label: columnHeading,
              value: this.rowsObj[x][i].replace(/%/, ""),
            });
          }
          // dataObjFiltered[x][columnHeading] = this.rowsObj[x][i].replace(/%/, '');
        }
      }
    }

    // Prune empty data rows

    if (this.options.removeEmptyDataRows) {
      i = dataObjFiltered.length;
      while (i--) {
        var noData = true;
        for (x = 0; x < dataObjFiltered[i].columns.length; x++) {
          if ($.isNumeric(dataObjFiltered[i].columns[x].value)) {
            noData = false;
          }
        }
        if (noData) {
          dataObjFiltered.splice(i, 1);
        }
      }
    }

    // Add colors

    var colors = this.colorsObj;
    if (this.showMetaToggle) {
      colors = this.createColorObject(heading);
    }
    if (colors.length === 0) {
      colors.push("#000");
    }
    for (x = 0; x < dataObjFiltered.length; x++) {
      for (i = 0; i < dataObjFiltered[x].columns.length; i++) {
        dataObjFiltered[x].columns[i].color = colors[i % colors.length];
      }
    }

    // Add data object to constructor and run limit
    this.dataObj = dataObjFiltered;
    this.limitDataObj();

    return this.dataObj;
  };

  // For use in createDataObj - "for" loop logic
  ChartData.prototype.dataLoopCond = function (heading) {
    heading = heading ? heading.toLowerCase() : heading;
    var self = this;
    // console.log('heading', heading);

    return function (i) {
      // console.log('dataLoopCond', i);
      if (self.columnsObj[i].show !== "1") {
        // console.log('noshow', i);
        return false;
      }
      if (self.showMetaToggle) {
        if (heading) {
          var metaHeading = self.columnsObj[i].metaheading.toLowerCase();
          metaHeading = metaHeading ? metaHeading : "total";
          // console.log('dlc2: returning ', heading === metaHeading);
          return heading === metaHeading;
        } else {
          if (self.headingType === "heading") {
            return true;
          }
          return i === 0; // Return the first displayable column
        }
      }
      return true;
    };
  };

  // Set a hard limit on rows (potentially for future use in CMS)
  ChartData.prototype.limitDataObj = function () {
    this.dataObj = this.dataObj.slice(0, this.dataLimit);

    return this.dataObj;
  };

  // Create Meta Headings Array based on Show column checkbox in CMS
  ChartData.prototype.getMetaHeadingsArray = function () {
    var columnLength = this.columnsObj.length,
      metaHeadings = [],
      columnMetaHeading,
      i;

    for (i = 0; i < columnLength; i++) {
      if (i !== 0 && this.columnsObj[i].show === "1") {
        if (this.columnsObj[i].metaheading === "") {
          metaHeadings.push("total");
        } else {
          columnMetaHeading = this.columnsObj[i].metaheading.toLowerCase();

          if (metaHeadings.indexOf(columnMetaHeading) === -1) {
            metaHeadings.push(columnMetaHeading);
          }
        }
      }
    }

    return metaHeadings;
  };

  ChartData.prototype.getFirstMetaheading = function () {
    // Start by skipping "Attribute" column

    // If all meta headings are empty, return false

    var metaheading = false;
    for (i = 1; i < this.columnsObj.length; i++) {
      if (this.columnsObj[i].show === "1" && this.columnsObj[i].metaheading) {
        metaheading = true;
        break;
      }
    }
    if (!metaheading) {
      return false;
    }
    for (i = 1; i < this.columnsObj.length; i++) {
      // console.log('getFirstMetaheading', i);
      if (this.columnsObj[i].show === "1") {
        if (this.columnsObj[i].metaheading) {
          metaheading = this.columnsObj[i].metaheading.toLowerCase();
        } else {
          metaheading = "total";
        }
        break;
      }
    }
    // console.log('getFirstMetaheading returning ', metaheading);
    return metaheading;
  };

  ChartData.prototype.getFirstHeading = function () {
    heading = false;
    for (i = 0; i < this.columnsObj.length; i++) {
      if (this.columnsObj[i].show === "1" && this.columnsObj[i].heading) {
        heading =
          this.columnsObj[i].displayheading || this.columnsObj[i].heading;
        heading = heading.toLowerCase();
        break;
      }
    }
    return heading;
  };

  // Create Headings Array based on Show column checkbox in CMS
  //
  // If a metaheading parameter is passed in, only the headings for that metaheading
  // will be returned.

  ChartData.prototype.getHeadingsArray = function (metaheading) {
    var columnLength = this.columnsObj.length,
      headings = [],
      heading,
      i;

    for (i = 0; i < columnLength; i++) {
      if (i !== 0 && this.columnsObj[i].show === "1") {
        if (metaheading) {
          if (
            this.columnsObj[i].metaheading.toLowerCase() !==
            metaheading.toLowerCase()
          ) {
            continue;
          }
        }
        heading =
          this.columnsObj[i].displayheading || this.columnsObj[i].heading;
        heading = heading.toLowerCase();

        if (headings.indexOf(heading) === -1) {
          headings.push(heading);
        }
      }
    }

    return headings;
  };

  // Return Largest Column Width
  ChartData.prototype.getLargestColumnWidth = function () {
    var columnLength = this.columnsObj.length,
      largestWidth = 1,
      columnWidth,
      i;

    for (i = 0; i < columnLength; i++) {
      columnWidth = parseInt(this.columnsObj[i].width);

      if (columnWidth > largestWidth) {
        largestWidth = columnWidth;
      }
    }

    return largestWidth;
  };

  // Calculate Width Multiplier for each column
  ChartData.prototype.widthMultiplier = function (heading) {
    var columnWidth, index, indexes, width;

    indexes = $.map(this.columnsObj, function (obj, index) {
      if (obj.heading === heading || obj.displayheading === heading) {
        return index;
      }
    });

    index = indexes[0];

    columnWidth = parseInt(this.columnsObj[index].width);

    if (!columnWidth) {
      width = 1;
    } else {
      width = columnWidth;
    }

    return width / this.largestWidth;
  };

  // createColorObject()
  //
  // This function processes the color array and orders them
  // according to the metaheading.  This allows different headings
  // to have different colors rather than always using the same
  // colors (CWP-10)
  //
  // This behavior can be overridden by setting the useSameColorsPerMeta to true.

  ChartData.prototype.createColorObject = function (metaheading) {
    var colorObj = [];
    var colorPalette = this.colorsObj.slice(); // make a copy of colorsObj
    var index;

    // If no metaheading is specified, use the first metaheading

    if (typeof metaheading === "undefined") {
      // We skip the first (attribute) column
      for (index = 1; index < this.columnsObj.length; index++) {
        if (
          typeof this.columnsObj[index].metaheading !== "undefined" &&
          this.columnsObj[index].show
        ) {
          metaheading = this.columnsObj[index].metaheading;
          break;
        }
      }
    }

    // Still not found?  Just give them the colorPalette.

    if (typeof metaheading === "undefined" || metaheading === null) {
      return colorPalette;
    }

    var colorIndices = this.getMetaHeadingIndices(metaheading);
    if (
      typeof colorIndices === "undefined" ||
      this.options.useSameColorsPerMeta
    ) {
      return colorPalette;
    }

    for (index = 0; index < colorIndices.length; index++) {
      colorObj.push(colorPalette[colorIndices[index] % colorPalette.length]);
    }

    return colorObj;
  };

  ChartData.prototype.getMetaHeadingIndices = function (metaheading) {
    // First get metaheadings
    if (!metaheading) {
      return 0;
    }
    metaheading = metaheading.toLowerCase();
    var index;
    var metaheadings = [];
    var metaheadingsSeen = [];

    for (index = 1; index < this.columnsObj.length; index++) {
      if (this.columnsObj[index].show) {
        var mh = this.columnsObj[index].metaheading;
        if (!mh) {
          mh = this.columnsObj[index].heading;
        }
        if (mh) {
          mh = mh.toLowerCase();
          if (!(mh in metaheadingsSeen)) {
            metaheadings.push(mh);
            metaheadingsSeen[mh] = 0;
          }
          metaheadingsSeen[mh]++;
        }
      }
    }
    // Now we have the metaheading order in metaheadings, and the number of occurrences of
    // each heading in metaheadingsSeen

    // Now we want to create a positional array for each metaheading

    index = 0;
    var positions = [];
    for (var k = 0; k < metaheadings.length; k++) {
      positions[metaheadings[k]] = [];
      for (var j = 0; j < metaheadingsSeen[metaheadings[k]]; j++) {
        positions[metaheadings[k]].push(index++);
      }
    }
    return positions[metaheading];
  };

  ChartData.prototype.getMaxNumColumns = function () {
    var count = 0;

    if (this.showMetaToggle) {
      var metaHeadings = this.getMetaHeadingsArray();
      for (var i = 0; i < metaHeadings.length; i++) {
        var tmpCount = this.countColumns(true, metaHeadings[i]);
        count = Math.max(count, tmpCount);
      }
    } else {
      count = this.countColumns();
    }
    return count;
  };

  ChartData.prototype.getMinNumColumns = function () {
    var count = false;

    if (this.showMetaToggle) {
      var metaHeadings = this.getMetaHeadingsArray();
      for (var i = 0; i < metaHeadings.length; i++) {
        var tmpCount = this.countColumns(true, metaHeadings[i]);
        if (count === false) {
          count = tmpCount;
        } else {
          count = Math.min(count, tmpCount);
        }
      }
    } else {
      count = this.countColumns();
    }
    if (count === false) {
      count = 0;
    }
    return count;
  };

  ChartData.prototype.countColumns = function (metaToggles, metaHeading) {
    metaToggles = typeof metaToggles !== "undefined" ? metaToggles : false;
    metaHeading = typeof metaHeading !== "undefined" ? metaHeading : "";

    var count = 0;
    for (var i = 1; i < this.columnsObj.length; i++) {
      var column = this.columnsObj[i];
      if (column.show === "1") {
        if (metaToggles) {
          if (
            metaHeading === column.metaheading.toLowerCase() ||
            (metaHeading === "total" && column.metaheading === "")
          ) {
            count++;
          }
        } else {
          count++;
        }
      }
    }
    return count;
  };

  return ChartData;
});

