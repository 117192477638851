define('app/articles-daily',["jquery"], function ($) {
  var exports = {
    init: function () {
      var articleTitle = $(".article-body .daily-heading").height();
      var articleBody = $(".article-body .article-content").height();
      var height = articleTitle + articleBody;
      var sidebar = $(".sidebar .articles");

      if ($(window).width() > 1024) {
        sidebar.css({
          height: height,
        });
      }
    },
  };

  return exports;
});

