define('app/recommend',["jquery"], function ($) {
  var exports = {
    init: function () {
      $(document).on("tertiaryNavLoaded", this.load);
      return this;
    },

    load: function () {
      var id = $(".article-body").data("id");

      $.ajax({
        method: "GET",
        url: "/actions/cassandra/entry/recommend",
        data: {
          id: id,
        },
        dataType: "html",
        success: function (html) {
          $(".article-body").append(html);
        },
      });
    },
  };

  return exports;
});

