define('app/print',["jquery"], function ($) {
  var printEl,
    $windowEl,
    originalPrint = window.print,
    imageUpgraded = false;

  var exports = {
    init: function () {
      printEl = $(".js-print");
      $windowEl = $(window);
      if (printEl.length > 0) {
        printEl.on("click", function () {
          $windowEl.trigger("printCharts");

          timeoutId = window.setTimeout(function () {
            window.print();
          }, 1000);
        });
      }

      // Swap original images for higher resolution
      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia("print");
        mediaQueryList.addListener(function (mql) {
          if (mql.matches) {
            beforePrint();
          } else {
            afterPrint();
          }
        });
      }

      window.onbeforeprint = beforePrint;
      window.onafterprint = afterPrint;
      return this;
    },
  };

  function upgradeImage(callback) {
    var src = $(".feature-print-img img").attr("src");
    if (src) {
      var newsrc = src.replace("_xlarge/", "");
      $(".feature-print-img img").attr("src", newsrc);
      $(".feature-print-img img").one("load", function () {
        imageUpgraded = true;
        if (callback) {
          callback();
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
  }

  window.print = function customPrint() {
    if (!imageUpgraded) {
      upgradeImage(originalPrint);
    } else {
      originalPrint();
    }
  };

  function beforePrint() {
    if (!imageUpgraded) {
      upgradeImage();
    }
  }

  function afterPrint() {}

  return exports;
});

