define('app/chart/v2/chart-horizontal-bar-stacked',["jquery", "d3", "app/chart/v2/chart"], function ($, d3, Chart) {
  var HorizontalBarStackedChart = function ($element, data, opts) {
    this.options = {
      headingType: "metaHeading",
      showMetaToggle: true,
      useSameColorsPerMeta: true,
      showColumnLegend: true,

      default: {
        margin: { top: 10, right: 150, bottom: 20, left: 150 },
        barGroupPadding: 0.2,
        barPadding: 0,
        rowPadding: 12,
        barHeight: 50,
      },

      mobile: {
        margin: { top: 10, right: 5, bottom: 0, left: 5 },
        barPadding: 2,
        barGroupPadding: 0.55,
        rowPadding: 40,
        barHeight: 85,
      },
    };

    // Call the super-constructor
    Chart.call(this, $element, data, opts);

    return this;
  };

  // HorizontalBarStackedChart class extends the base Chart class.
  HorizontalBarStackedChart.prototype = Object.create(Chart.prototype);

  HorizontalBarStackedChart.prototype.draw = function () {
    var chartData = this.chartData;
    var my = this;
    var margins = this.getMargins();
    var data = this.data;
    var xBegin;
    var svgWidth = this.getSvgWidth();
    var barHeight, barGroupPadding, barPadding, rowPadding;

    // Set yPadding based on screen size for bar width
    var yPadding = this.isMobile() ? svgWidth : margins.left;

    var rowHeaders = this.getKeys();

    data.forEach(function (d) {
      d.rowDetails = [];
      var i = 0;
      d.columns.forEach(function (c) {
        c.xBegin = +i;
        if (!isNaN(c.value)) {
          i += +c.value;
        }
        c.xEnd = i;
      });

      d.total = d3.max(d.columns, function (c) {
        return c.xEnd;
      });
    });

    var numBars = data.length;

    // Mobile attribute changes
    if (this.isMobile()) {
      barHeight = this.options.mobile.barHeight;
      barGroupPadding = this.options.mobile.barGroupPadding;
      barPadding = this.options.mobile.barPadding;
      rowPadding = this.options.mobile.rowPadding;
    } else {
      barHeight = this.options.default.barHeight;
      barGroupPadding = this.options.default.barGroupPadding;
      barPadding = this.options.default.barPadding;
      rowPadding = this.options.default.rowPadding;
    }

    // Width and Height calculations
    var width = svgWidth - margins.left - margins.right,
      height = barHeight * numBars - margins.top - margins.bottom;

    // Note that these calculations will be re-done after laying out labels
    // That is, look for height calculations further down...

    // over-ride existing height, which is set in chart.js

    d3.select(this.chartIdSelector).attr("height", height);

    // Draw the chart
    var x = d3.scale.linear().rangeRound([0, width]);

    var y0 = d3.scale.ordinal().rangeRoundBands([0, height], barGroupPadding);

    var y1 = d3.scale.ordinal();

    var xAxis = d3.svg.axis().scale(x).orient("bottom");

    var yAxis = d3.svg.axis().scale(y0).orient("left");

    var chart = this.chart.select(".visual");

    x.domain([
      0,
      d3.max(data, function (d) {
        return d.total;
      }),
    ]);
    y0.domain(
      data.map(function (d) {
        return d.attribute;
      })
    );
    y1.domain([0, y0.rangeBand()]);

    chart
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    var yAxisText = chart
      .append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + margins.left * -1 + ", 0)")
      .call(yAxis)
      .selectAll(".tick text")
      .call(this.wrapText, yPadding - 10)
      .style("text-anchor", "start");

    if (this.isMobile()) {
      yAxisText
        .selectAll("tspan")
        .attr("y", y0.rangeBand() / 2 + 10)
        .attr("x", margins.left);
    }

    // Now that we've laid out the text labels, we'll need to re-do all
    // the height calculations!

    // Measure the y axis labels.  Our bar height will be a multiple of the
    // number of lines in the biggest y axis label.

    var textMaxHeight = 39;
    $(".y.axis .tick text").each(function (idx, el) {
      var bbox = d3.select(el).node().getBBox();
      textMaxHeight = Math.max(bbox.height, textMaxHeight);
    });

    chart.html(""); // clear out chart to start over

    height = numBars * (textMaxHeight + rowPadding);

    d3.select(this.chartIdSelector).attr(
      "height",
      height + this.legendHeight + margins.top + margins.bottom
    );

    y0 = d3.scale.ordinal().rangeRoundBands([0, height], barGroupPadding);

    yAxis = d3.svg.axis().scale(y0).orient("left");

    y0.domain(
      data.map(function (d) {
        return d.attribute;
      })
    );
    // y1.domain([0, y0.rangeBand()]);

    chart
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    yAxisText = chart
      .append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + margins.left * -1 + ", 0)")
      .call(yAxis)
      .selectAll(".tick text")
      .call(this.wrapText, yPadding - 10)
      .style("text-anchor", "start");

    if (this.isMobile()) {
      yAxisText
        .selectAll("tspan")
        .attr("y", textMaxHeight / 2 + 12)
        .attr("x", margins.left);
    }

    // Moving on now...

    var group = chart
      .selectAll(".group")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "g")
      .attr("transform", function (d) {
        return "translate(0, " + y0(d.attribute) + ")";
      });

    group
      .selectAll("rect")
      .data(function (d) {
        return d.columns;
      })
      .enter()
      .append("rect")
      .attr("class", "bar-rect")
      .attr("height", textMaxHeight)
      .attr("x", function (d) {
        return x(d.xBegin);
      })
      .attr("y", function (d) {
        return 0;
      })
      // .attr("y", function(d) { return y1(0) + barPadding / 2; })
      .attr("width", 0)
      .style("fill", function (d) {
        return d.color;
      });

    if (this.animate) {
      group
        .selectAll("rect")
        .transition()
        .attr("width", function (d) {
          return x(d.xEnd) - x(d.xBegin);
        })
        .duration(my.animationDuration)
        .delay(my.animationDelay);
    } else {
      group.selectAll("rect").attr("width", function (d) {
        return x(d.xEnd) - x(d.xBegin);
      });
    }

    group
      .selectAll("text")
      .data(function (d) {
        return d.columns;
      })
      .enter()
      .append("text")
      .attr("x", function (d) {
        var value = 0;
        if (isNaN(d.xBegin) || isNaN(d.xEnd)) {
          return 0;
        }
        var labelWidth = value < 10 ? 20 : 28; // approximation

        return x(d.xBegin);
      })
      .attr("y", function (d) {
        return textMaxHeight / 2;
      })
      .attr("dy", ".35em");

    if (this.animate) {
      group
        .selectAll("text")
        .text("0%")
        .transition()
        .duration(my.animationDuration)
        .tween("text", function (d) {
          var i = d3.interpolateRound(0, d.xEnd - d.xBegin);
          return function (t) {
            var value = i(t);
            if (value === 0 || isNaN(value)) {
              this.textContent = "";
            } else {
              this.textContent = value + "%";
            }
          };
        })
        .attr("x", function (d) {
          var value = 0;
          if (isNaN(d.xBegin) || isNaN(d.xEnd)) {
            return 0;
          }
          var labelWidth = value < 10 ? 20 : 28; // approximation

          return x(d.xBegin + (d.xEnd - d.xBegin) / 2) - labelWidth / 2;
        })
        .delay(my.animationDelay);
    } else {
      group
        .selectAll("text")
        .text(function (d) {
          var value = d.xEnd - d.xBegin;
          if (value === 0 || isNaN(value)) {
            return "";
          }
          return value + "%";
        })
        .attr("x", function (d) {
          var value = 0;
          if (isNaN(d.xBegin) || isNaN(d.xEnd)) {
            return 0;
          }
          var labelWidth = value < 10 ? 20 : 28; // approximation

          return x(d.xBegin + (d.xEnd - d.xBegin) / 2) - labelWidth / 2;
        });
    }

    if (!this.isMobile()) {
      this.adjustYLabelsVertically(-7);
    }
  };

  return HorizontalBarStackedChart;
});

