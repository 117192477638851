define('app/clicks',["jquery"], function ($) {
  var clickableDiv = $(".js-clickable-div"),
    loginButton = $(".js-login-button");

  var exports = {
    init: function () {
      exports.toggleAppendix();
      exports.toggleByDate();
      exports.navHelperClose();
      $(window).on("load resize", function () {
        exports.navHelperOpen();
      });
      exports.toggleEventSignup();
      exports.submitEventSignup();
      exports.toggleDailyPageDailySignup();
      exports.toggleHomepageDailySignup();
      exports.dailySubmitDailySignup();
      exports.homepageSubmitDailySignup();

      return this;
    },

    toggleAppendix: function () {
      // Appendix below charts
      $(".show-data-button").each(function () {
        $(this).on("click", function () {
          var $selfEl = $(this);

          if (!$selfEl.hasClass("active")) {
            $selfEl.text($selfEl.data("hide-data-button-label"));

            $("html,body").animate(
              {
                scrollTop: $selfEl.offset().top - 80, // margin for top header
                complete: $(document).trigger("scroll"), // to adjust tertiary nav placement
              },
              300
            );
          } else {
            $selfEl.text($selfEl.data("view-data-button-label"));
          }

          var el1 = $("#appendix-" + $selfEl.data("id"));
          var $appendix = $("#appendix-" + $selfEl.data("id")).closest(
            ".appendix"
          );
          if ($appendix) {
            $appendix.slideToggle("slow");
          }
          $selfEl.toggleClass("active");
        });
      });
    },

    toggleByDate: function () {
      // Drop down menu on dynamic pages
      $(".js-toggle-by-date").on("click", function () {
        $(".article-sort-list").slideToggle("fast");
        $(this).children(".fa").toggleClass("fa-rotate-180");
      });
    },

    navHelperClose: function () {
      // Menu item helper texts
      $(".nav-item-description-close").on("click", function () {
        $(".nav-item-description").fadeOut(300);
        $.ajax("accounts/closeNavHelper", {
          complete: function (data) {
            // console.log('complete', data);
          },
        });
      });
    },

    navHelperOpen: function () {
      // Menu item helper texts
      var menuWidth = $("nav.main-nav").width();

      $(".nav-item-description").css({
        "max-width": menuWidth,
      });
    },

    toggleHomepageDailySignup: function () {
      var copy = $(".homepage-daily-slider .description .copy");
      var form = $(".homepage-daily-slider .description form");

      $(".homepage-daily-slider .description .subscribe").on(
        "click",
        function () {
          copy.slideUp(function () {
            form.slideDown();
          });

          return false;
        }
      );

      $(".homepage-daily-slider .description .close").on("click", function () {
        form.slideUp(function () {
          copy.slideDown();
        });

        return false;
      });
    },

    toggleLoginDialog: function () {
      var link = $("nav .login-link");
      var linkParent = link.parent(".nav-item");
      var loginDialog = $("header .login-dialog");

      link.on("click", function () {
        if (linkParent.hasClass("active")) {
          loginDialog.slideUp();
          linkParent.removeClass("active");
        } else {
          loginDialog.slideDown();
          linkParent.addClass("active");
        }

        return false;
      });
    },

    toggleDailyPageDailySignup: function () {
      var link = $(".subscribe-daily-link a");
      var linkParent = $(".subscribe-daily-link");
      var dialog = $(".subscribe-daily-dialog");
      var caretUp = $(".subscribe-daily-link .fa-caret-up");
      var caretDown = $(".subscribe-daily-link .fa-caret-down");

      link.on("click", function () {
        if (linkParent.hasClass("active")) {
          dialog.slideUp(function () {
            caretUp.hide();
            caretDown.show();
          });
          linkParent.removeClass("active");
        } else {
          dialog.slideDown(function () {
            caretUp.show();
            caretDown.hide();
          });
          linkParent.addClass("active");
        }

        return false;
      });
    },

    toggleEventSignup: function () {
      var link = $(".event-signup-link a");
      var linkParent = $(".event-signup-link");
      var dialog = $(".event-signup-dialog");
      var caretUp = $(".event-signup-link .fa-caret-up");
      var caretDown = $(".event-signup-link .fa-caret-down");

      link.on("click", function () {
        if (linkParent.hasClass("active")) {
          dialog.slideUp(function () {
            caretUp.hide();
            caretDown.show();
          });
          linkParent.removeClass("active");
        } else {
          dialog.slideDown(function () {
            caretUp.show();
            caretDown.hide();
          });
          linkParent.addClass("active");
        }

        return false;
      });
    },

    submitEventSignup: function () {
      var link = $(".event-signup-link a");
      var thankYou = $(".event-signup-link p");
      var linkParent = $(".event-signup-link");
      var dialog = $(".event-signup-dialog");

      $("#event-signup-form").submit(function (e) {
        e.preventDefault();

        var data = $(this).serialize();

        $.ajax({
          method: "POST",
          url: "/",
          data: data,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
          .success(function (data) {
            if (data.success) {
              dialog.slideUp();
              linkParent.removeClass("active");
              link.hide();
              thankYou.show();
            }
          })
          .error(function (data) {});
      });
    },

    dailySubmitDailySignup: function () {
      $(".subscribe-daily-dialog form").submit(function (e) {
        e.preventDefault();
        var form = this;
        var data = $(this).serialize();
        var fname = $('input[name="fname"]').val();
        var lname = $('input[name="lname"]').val();
        var email = $('input[data="daily-email"]').val();
        var title = $('input[name="title"]').val();
        var company = $('input[name="company"]').val();
        var personal = document.getElementById("daily-form-personal");
        var business = document.getElementById("daily-form-business");
        if (
          exports.vaildateFormField(fname) &&
          exports.vaildateFormField(lname) &&
          exports.validateEmail(email) &&
          exports.vaildateFormField(title) &&
          company !== "" &&
          (personal.checked || business.checked)
        ) {
          $(".subscribe-daily-dialog .subscribe-error").hide();
          var request = $.ajax({
            method: "POST",
            url: "/subscribe",
            data: data,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          });
          request.done(function (data) {
            if (data.success) {
              form.reset();
              $(form).hide();
              $("#success").show();
            } else {
              $error = $('<p>').text(data.title);
              $(".subscribe-daily-dialog .subscribe-error").html($error).show();
            }
          });
          request.fail(function (data) {
            form.reset();
          });
        } else {
          $(".subscribe-daily-dialog .subscribe-error").show();
        }
      });
    },

    homepageSubmitDailySignup: function () {
      $("#homepage-daily-signup").submit(function (e) {
        e.preventDefault();
        var form = this;
        var data = $(this).serialize();
        var fname = $('input[name="fname"]').val();
        var lname = $('input[name="lname"]').val();
        var email = $('input[data="daily-email"]').val();
        var title = $('input[name="title"]').val();
        var company = $('input[name="company"]').val();
        var personal = document.getElementById("daily-form-personal");
        var business = document.getElementById("daily-form-business");
        if (
          exports.vaildateFormField(fname) &&
          exports.vaildateFormField(lname) &&
          exports.validateEmail(email) &&
          exports.vaildateFormField(title) &&
          company !== "" &&
          (personal.checked || business.checked)
        ) {
          $(".description .subscribe-error").hide();
          var request = $.ajax({
            method: "POST",
            url: "/subscribe",
            data: data,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          });
          request.done(function (data) {
            if (data.success) {
              form.reset();
              $(form).hide();
              $("#success").show();
            } else {
              $error = $('<p>').text(data.title);
              $(".description .subscribe-error").html($error).show();
            }
          });
          request.fail(function (data) {
            form.reset();
          });
        } else {
          $(".description .subscribe-error").show();
        }
      });
    },

    validateEmail: function (email) {
      var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      return emailReg.test(email);
    },

    vaildateFormField: function (str) {
      var stringReg = /[a-z0-9]+/i;
      return stringReg.test(str);
    },
  };

  return exports;
});

