define('app/home',["jquery"], function ($) {
  var exports = {
    init: function () {
      exports.dynamicNav();
    },

    dynamicNav: function (e) {
      var header = $("header"),
        headerHeight = header.outerHeight(),
        section = $(".homepage-carousel"),
        sectionOffsetBottom = section.offset().top + section.outerHeight();

      $(window).on("load resize scroll", function () {
        var scrolled = $(this).scrollTop() + headerHeight;

        if (scrolled < sectionOffsetBottom) {
          header.addClass("white");
        } else {
          header.removeClass("white");
        }
      });
    },
  };

  return exports;
});

