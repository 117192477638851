define('app/chart/v2/chart',[
  "app/chart/v2/chart-data",
  "app/chart/legend",
  "d3",
  "jquery",
], function (ChartData, Legend, d3, $) {
  /**
   * Chart: Base type for all charts
   *
   * Note the options cascade: (from lowest priority to highest priority)
   *
   * 1) chart-data.js default options
   * 2) bubble-chart.js default options (or whichever specific chart)
   * 3) embedded data options (within the chart DOM data attribute)
   * 4) JSON data
   * 5) options parameter
   *
   * The ChartData object gets the same copy of the options that the Chart has.
   *
   * Chart layout:
   *
   * SVG:
   *
   * div.chart-wrap
   * 	div.chart-heading
   * 	ul.chart-toggle (optional)
   *  ul.legend (optional)
   * 	svg.chart (with appendix data-attribute) (this.$element)
   *
   * HTML:
   *
   * div.chart-wrap
   *	 div.chart-heading
   *   ul.chart-toggle (optional)
   *   ul.legend (optional)
   *	 div.chart (with appendix data-attribute) (this.$element)
   */

  var Chart = function ($element, chartData, options) {
    this.$element = $element;
    this.chartId = $element[0].id;
    this.chartIdSelector = "#" + this.chartId;
    this.chart = d3.select(this.chartIdSelector);
    this.activeMetatoggle = null;
    this.animate = true; /* animate the drawing of the chart */
    this.createAxes = true; /* set up an x and y axis for each chart */
    this.multiChart = false; /* if the chart contains multiple graphics */

    this.dataLimit = 50;
    this.animationDuration = 1000;
    this.animationDelay = 100;

    this.margin = 0;
    this.barFixedHeight = 0;
    this.barPadding = 5;
    this.barGroupPadding = 0.1;
    this.heightMultiplier = 0.6;
    this.lineHeight = 17;
    this.legendHeight = 0;

    // if (options.default)
    // {
    // 	$.extend(this, options.default);
    // }
    var defaultOptions = this.options;
    if (!chartData) {
      chartData = $element.data("appendix");
    }
    $.extend(defaultOptions, chartData.options);
    chartData.options = defaultOptions;

    this.chartData = new ChartData(chartData, options);
    // copy over the new options
    this.options = this.chartData.options;

    this.getData();

    $element.data("chart-data", this.chartData);
  };

  Chart.prototype.draw = function (animate) {
    // descendant objects should implement this
  };

  Chart.prototype.getData = function () {
    // Set data variable
    // the data variable is an array (one element per row of data; each element
    // has key for each heading and value)
    if (this.chartData.showMetaToggle && this.activeMetatoggle === null) {
      this.activeMetatoggle = this.chartData.getFirstMetaheading();
    }
    this.data = this.chartData.createDataObj(this.activeMetatoggle);

    if (
      this.chartData.showMetaToggle &&
      this.chartData.getFirstMetaheading() !== false
    ) {
      this.colors = this.chartData.createColorObject(this.activeMetatoggle);
    } else {
      this.colors = this.chartData.colorsObj;
    }
  };

  Chart.prototype.show = function () {
    var chartData = this.chartData;
    var my = this;

    if (chartData) {
      if (
        chartData.showMetaToggle &&
        this.chartData.getFirstMetaheading() !== false
      ) {
        this.insertHeadingButtons(
          this.activeMetatoggle,
          function ($el, heading) {
            my.metaToggleCallback.call(my, heading);
          }
        );
      }
      var margins = this.getMargins();
      this.width =
        this.$element.parent().width() - margins.left - margins.right;
      this.height =
        this.$element.parent().width() * this.heightMultiplier -
        margins.top -
        margins.bottom;

      if (this.createAxes) {
        this.setupAxes();
      }

      if (this.multiChart) {
        this.multiShow();
      } else {
        this.singleShow();
      }
    }
    this.draw();
  };

  Chart.prototype.singleShow = function () {
    var chartData = this.chartData;
    var margins = this.getMargins();
    this.chart
      .html("") // Clear SVGs for redraw on resize
      .attr("width", this.width + margins.left + margins.right)
      .attr("height", this.height + margins.top + margins.bottom)
      // .style('border', 'solid 1px black')
      .classed("is-not-loaded", false)
      .append("g")
      .classed("visual", true)
      .attr("transform", "translate(" + margins.left + "," + margins.top + ")");

    // We only want a legend if there's more than one column of data; otherwise
    // it's simply duplicating the metatoggle.

    var legendHeight = 0;

    // Clear any existing legend

    this.$element.siblings(".legend").remove();

    if (this.options.showColumnLegend && this.data[0].columns.length > 1) {
      var legendObj = Legend.createData(this.getKeys(), this.colors);
      var legend = new Legend($(this.chartIdSelector), legendObj);
    }
  };

  /**
   * multi chart elements should be divs (not SVGs)
   *
   * multiShow will empty the div, then add a UL with an SVG for each chart.
   */

  Chart.prototype.multiShow = function () {
    var chartData = this.chartData;
    this.$element.empty().append('<ul class="charts"></ul>');
    var segments = this.data[0].keys;
    for (var i = 0; i < segments.length; i++) {
      this.$element
        .find(".charts")
        .append(
          '<li class="chart-group"><svg id="' +
            this.chartId +
            "-" +
            i +
            '" width="100%" height="300"></svg><div class="chart-label"></div></li>'
        );
    }
    this.$element.removeClass("is-not-loaded");
  };

  Chart.prototype.metaToggleCallback = function (metaToggle) {
    this.$element.empty();
    this.activeMetatoggle = metaToggle;
    this.getData();
    this.show();
  };

  Chart.prototype.setupAxes = function () {
    // Set up d3 scales //
    this.x = d3.scale.ordinal().rangeRoundBands([0, this.width]);

    this.y = d3.scale.linear();

    this.xAxis = d3.svg
      .axis()
      .scale(this.x)
      .orient("bottom")
      .innerTickSize(this.height)
      .tickPadding(15);

    this.yAxis = d3.svg.axis().scale(this.y).orient("right");
  };

  Chart.prototype.getMargins = function () {
    if (this.isMobile()) {
      return this.options.mobile.margin;
    }
    return this.options.default.margin;
  };

  // Create Meta Button unordered list and insert directly before chart
  Chart.prototype.insertHeadingButtons = function (heading, callback) {
    var chartData = this.chartData;

    if (chartData.showMetaToggle) {
      var self = this,
        i,
        headings,
        itemClass,
        toggleHtml;

      // Determine type of heading used for sorting
      if (chartData.headingType === "metaHeading") {
        headings = chartData.metaHeadingsArray;
        if (!heading || heading === "total") {
          heading = headings[0];
        }
      } else if (chartData.headingType === "heading") {
        headings = chartData.headingsArray;
        if (!heading) {
          heading = headings[0];
        }
      }

      // Remove existing buttons
      $("#js-heading-toggle-" + this.chartId).remove();

      // Create buttons HTML
      toggleHtml = '<ul class="button-list chart-toggle" ';
      toggleHtml += 'id="js-heading-toggle-' + this.chartId + '">';

      if (headings.length > 1) {
        for (i = 0; i < headings.length; i++) {
          itemClass = "chart-toggle-item";
          if (headings[i] === heading) {
            itemClass += " active";
          }

          toggleHtml += '<li class="' + itemClass + '"';
          toggleHtml += ' data-heading="' + headings[i] + '">';
          toggleHtml += headings[i] + "</li>";
        }
      }
      toggleHtml += "</ul>";

      // Insert buttons before chart
      this.$element.before(toggleHtml);

      // Add click to toggle buttons
      this.$element
        .prev()
        .children()
        .each(function () {
          $(this).on("click", function () {
            var heading = $(this).data("heading");

            callback(self.$element, heading);
          });
        });
    }

    return this;
  };

  Chart.prototype.largestAxisLabel = function (drawnAxis) {
    var axisArray = drawnAxis[0],
      axisLength = axisArray.length,
      largestLabel = 1,
      axisChildNodesLength,
      i;

    for (i = 0; i < axisLength; i++) {
      axisChildNodesLength = parseInt(axisArray[i].childNodes.length);

      if (axisChildNodesLength > largestLabel) {
        largestLabel = axisChildNodesLength;
      }
    }

    return largestLabel;
  };

  Chart.prototype.notANumberTextHandler = function (value) {
    if (!isNaN(value)) {
      var i = d3.interpolateRound(0, value);
      return function (t) {
        this.textContent = i(t) + "%";
      };
    } else {
      return function (t) {
        this.textContent = "N/A";
      };
    }
  };

  // Cancel animations based on true variable
  Chart.prototype.cancelAnimations = function (condition) {
    if (condition) {
      this.animationDuration = 0;
      this.animationDelay = 0;
    }
  };

  Chart.prototype.isMobile = function () {
    // Screen Attributes w/ defaults
    var windowWidth = $(window).width();
    return windowWidth < 768;
  };

  Chart.prototype.getSvgWidth = function () {
    return this.$element.parent().width();
  };

  // Find middle of angle in SVG circle charts
  Chart.prototype.midAngle = function (d) {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
  };

  Chart.prototype.getDataKeys = function () {
    var keys = d3.keys(this.dataObj[0]).filter(function (key) {
      return key !== this.attributeTitle;
    });

    return keys;
  };

  // Wrap text to specified width in SVG <text> element
  Chart.prototype.wrapText = function (text, width, verticalCenter) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy + "em");

      while (words.length > 0) {
        word = words.pop();
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
      if (verticalCenter) {
        var tcount = text.selectAll("tspan").size();
        text.selectAll("tspan").attr("dy", function () {
          var ts = d3.select(this);
          var dy = parseFloat(ts.attr("dy"));
          var newDy = dy - (tcount - 1) * 0.5;

          return newDy + "em";
        });
      }
    });

    return this;
  };

  /**
   * adjustYLablesVerticall(inc)
   *
   * Long y labels will break into multiple lines; this function adjusts
   * the vertical centering for those labels, using an increment provided
   * per line.  As an example, the stacked horizontal bar chart sets an
   * increment of -7 per line.
   */

  Chart.prototype.adjustYLabelsVertically = function (inc) {
    this.$element.find(".y.axis .tick").each(function (i, el) {
      var tspans = $(el).find("text tspan");
      tspans.each(function (j, tspan) {
        $(tspan).attr("y", parseInt(inc * (tspans.length - 1)));
      });
    });
  };

  Chart.prototype.getKeys = function () {
    var keys = [];
    if (this.data && this.data[0].columns) {
      for (var i = 0; i < this.data[0].columns.length; i++) {
        keys.push(this.data[0].columns[i].label);
      }
    }
    return keys;
  };

  Chart.prototype.calculateMaxYLabelHeight = function (width) {
    var chart = d3.select(this.chartIdSelector);
    var answer = {};

    chart
      .append("g")
      .attr("class", "maxYLabelHeightTmp")
      .selectAll("text")
      .data(this.data)
      .enter()
      .append("text")
      .attr("dy", "0")
      .attr("y", "0")
      .style("font-size", "1rem")
      .text(function (d) {
        return d.attribute;
      })
      .style("text-anchor", "start")
      .call(this.wrapText, width)
      .call(this.measureMaxLabelHeight, answer);

    chart.select(".maxYLabelHeightTmp").remove();

    return answer;
  };

  /* measureMaxLabelHeight() is used by calculateMaxYLabelHeight. */

  Chart.prototype.measureMaxLabelHeight = function (text, answer) {
    answer.maxLabelHeight = 0;
    answer.maxLabelLines = 0;
    answer.lineHeight = 0;
    text.each(function () {
      answer.maxLabelHeight = Math.max(
        answer.maxLabelHeight,
        this.getBBox().height
      );
      answer.maxLabelLines = Math.max(
        answer.maxLabelLines,
        d3.select(this).selectAll("tspan").size()
      );
      answer.lineHeight = d3
        .select(this)
        .select("tspan")
        .node()
        .getBBox().height;
    });
  };

  return Chart;
});

