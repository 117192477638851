define('app/solutions',["jquery"], function ($) {
  var exports = {
    init: function () {
      $("#solutions-page .solution .toggler").click(function () {
        exports.toggle(this);
        return false;
      });

      return this;
    },
    toggle: function (el) {
      var solution = $(el).parents(".solution");

      if ($(el).hasClass("open")) {
        solution.find(".bodyContent").slideUp(function () {
          $(el).children(".close").hide();
          $(el).children(".more-info").show();
          $(el).removeClass("open").addClass("closed");
        });
      } else {
        solution.find(".bodyContent").slideDown(function () {
          $(el).children(".close").show();
          $(el).children(".more-info").hide();
          $(el).removeClass("closed").addClass("open");
        });
      }

      return false;
    },
  };

  return exports;
});

