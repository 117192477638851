define('app/chart/chart-gauge',["jquery", "d3", "app/chart/chart-attr"], function ($, d3, ChartAttr) {
  var exports = {
    init: function ($element) {
      exports.loopThroughSVGs($element);

      return this;
    },

    loopThroughSVGs: function ($element) {
      var i;

      // Collect chart data attributes
      var chartAttr = new ChartAttr($element, {
        default: {
          headingType: "metaHeading",
          margin: { gauge: 15, top: 10, right: 45, bottom: 20, left: 45 },
          barPadding: 30,
          heightMultiplier: 50,
          animationDuration: 1000,
          svgWidth: 304, // 19rem
        },

        mobile: {
          margin: { gauge: 0, top: 10, right: 45, bottom: 20, left: 45 },
        },
      });

      // Parse data
      var data = chartAttr.createDataObj();
      var loopLength = data.length / 2; // Halved to account for two charts in one

      chartAttr.$thisEl.removeClass("is-not-loaded");

      /* Execute Build Chart Function */
      for (i = 0; i < loopLength; i++) {
        exports.buildChart(chartAttr, i);
      }

      return this;
    },

    buildChart: function (chartAttr, index) {
      chartAttr.updateSettings({
        default: {
          gaugeTitle: chartAttr.columnsObj[1].heading,
        },
      });

      // Misc Variables
      var pi = Math.PI,
        startIndex = index * 2,
        dataSubSet = chartAttr.dataObj.slice(startIndex, startIndex + 2),
        barAmount = Object.keys(dataSubSet[0]).length - 2,
        legendWidth = 300;

      var width =
          chartAttr.svgWidth - chartAttr.margin.left - chartAttr.margin.right,
        height = chartAttr.svgWidth + barAmount * chartAttr.heightMultiplier,
        gaugeHeight = chartAttr.svgWidth * 0.8,
        barsHeight = height - gaugeHeight - chartAttr.margin.bottom,
        radius = chartAttr.svgWidth / 2 - chartAttr.margin.gauge;

      /////////////////////////////
      // Draw the Half Pie Chart //
      /////////////////////////////

      var halfPieChart = d3
        .select(chartAttr.chartIdSelector + "-" + index)
        .attr("width", chartAttr.svgWidth)
        .attr("height", height)
        .classed("not-loaded", false)
        .append("g")
        .attr(
          "transform",
          "translate(" + chartAttr.svgWidth / 2 + "," + radius * 1.2 + ")"
        );

      var pie = d3.layout
        .pie()
        .sort(null)
        .value(function (d) {
          return d[chartAttr.gaugeTitle];
        })
        .startAngle(-90 * (pi / 180))
        .endAngle(90 * (pi / 180));

      var arc = d3.svg.arc().outerRadius(radius).innerRadius(0);

      var tweenPie = function (b) {
        b.innerRadius = 0;
        var i = d3.interpolate(
          {
            startAngle: 0,
            endAngle: 0,
          },
          b
        );
        return function (t) {
          return arc(i(t));
        };
      };

      /* ------- PIE SLICES ------- */

      var slices = halfPieChart
        .selectAll("g.slice")
        .data(pie(dataSubSet))
        .enter()
        .append("g")
        .attr("class", "slice");

      slices
        .append("path")
        .attr("fill", function (d, i) {
          return chartAttr.colorsObj[i];
        })
        .transition()
        .ease("cubic-in-out")
        .duration(chartAttr.animationDuration)
        .attrTween("d", tweenPie);

      slices
        .append("text")
        .attr("transform", function (d, i) {
          pos = [radius / 2, radius * -1];

          if (i === 0) {
            pos[0] = pos[0] * -1;
          }
          return "translate(" + pos + ")";
        })
        .attr("dy", ".35em")
        .style("text-anchor", function (d, i) {
          return i === 1 ? "start" : "end";
        })
        .text("0%")
        .attr("class", "slice-label")
        .attr("opacity", 0)
        .transition()
        .attr("opacity", 1)
        .tween("text", function (d, i) {
          var inter = d3.interpolateRound(0, d.value);
          return function (t) {
            this.textContent = inter(t) + "%";
          };
        })
        .duration(chartAttr.animationDuration)
        .delay(chartAttr.animationDelay);

      /* ------- TEXT LABELS ------- */

      halfPieChart.append("g").attr("class", "labels");

      var text = halfPieChart
        .select(".labels")
        .selectAll("text")
        .data(pie(dataSubSet));

      text
        .enter()
        .append("text")
        .attr("opacity", 0)
        .attr("y", 20)
        .attr("dy", ".32em")
        .attr("class", "gauge-labels")
        .text(function (d) {
          return d.data[chartAttr.attributeTitle];
        })
        .call(chartAttr.wrapText, radius - 5);

      text
        .transition()
        .duration(chartAttr.animationDuration / 2)
        .attr("transform", function (d, i) {
          pos = [radius / 2, chartAttr.margin.gauge / 2];

          if (i === 0) {
            pos[0] = pos[0] * -1;
          }

          return "translate(" + pos + ")";
        })
        .style("text-anchor", "middle")
        .attr("opacity", 1)
        .delay(chartAttr.animationDelay * 8);

      text.exit().remove();

      ///////////////////////////////////////////
      // Draw the Diverging Stacked Bar charts //
      ///////////////////////////////////////////

      var barHeaders = d3.keys(dataSubSet[0]).filter(function (key) {
        return key !== chartAttr.attributeTitle && key !== chartAttr.gaugeTitle;
      });

      var barsObject = (function () {
        var i,
          x,
          tempObject,
          object = [];

        for (i = 0; i < barHeaders.length; i++) {
          tempObject = {};
          tempObject.header = barHeaders[i];
          tempObject.values = [];

          for (x = 0; x < dataSubSet.length; x++) {
            tempObject.values.push(dataSubSet[x][tempObject.header]);
          }

          object.push(tempObject);
        }

        return object;
      })();

      var y = d3.scale
        .ordinal()
        .rangeRoundBands([0, barsHeight])
        .domain(
          barHeaders.map(function (d) {
            return d;
          })
        );

      var x = d3.scale
        .linear()
        // .rangeRound([0, chartAttr.svgWidth])
        .rangeRound([0, width])
        .domain([-100, 100]);

      var colors = d3.scale
        .ordinal()
        .range(chartAttr.colorsObj)
        .domain(d3.keys(barHeaders));

      var xAxis = d3.svg.axis().scale(x).orient("top");

      var yAxis = d3.svg.axis().scale(y).orient("left");

      var stackedBarChart = d3
        .select(chartAttr.chartIdSelector + "-" + index)
        .append("g")
        .attr("transform", "translate(0," + gaugeHeight + ")");

      stackedBarChart.append("g").attr("class", "x axis").call(xAxis);

      stackedBarChart.append("g").attr("class", "y axis").call(yAxis);

      var group = stackedBarChart
        .selectAll(".group")
        .data(barsObject)
        .enter()
        .append("g")
        .attr("class", "g")
        .attr("transform", function (d) {
          return (
            "translate(" + chartAttr.margin.left + ", " + y(d.header) + ")"
          );
        });

      // Addd stacked bar labels
      group
        .append("text")
        .text(function (d) {
          return d.header;
        })
        .attr("x", width / 2)
        .attr("y", y.rangeBand() + 2)
        .style("text-anchor", "middle");

      group
        .append("rect")
        .attr("class", "bar-rect")
        .attr("height", y.rangeBand() - chartAttr.barPadding)
        .attr("x", function (d) {
          return x(0);
        })
        .attr("y", function (d) {
          return 0 + chartAttr.barPadding / 2;
        })
        .attr("width", 0)
        .style("fill", chartAttr.colorsObj[0])
        .transition()
        .attr("width", function (d) {
          return x(d.values[0]) - x(0);
        })
        .attr("x", function (d) {
          return x(0) - (x(d.values[0]) - x(0));
        })
        .duration(chartAttr.animationDuration)
        .delay(chartAttr.animationDelay);

      group
        .append("rect")
        .attr("class", "bar-rect")
        .attr("height", y.rangeBand() - chartAttr.barPadding)
        .attr("x", function (d) {
          return x(0);
        })
        .attr("y", function (d) {
          return 0 + chartAttr.barPadding / 2;
        })
        .attr("width", 0)
        .style("fill", chartAttr.colorsObj[1])
        .transition()
        .attr("width", function (d) {
          return x(d.values[1]) - x(0);
        })
        .duration(chartAttr.animationDuration)
        .delay(chartAttr.animationDelay);

      group
        .append("text")
        .text("0%")
        .attr("class", "bar-text")
        .attr("x", function (d) {
          return x(0) - 5;
        })
        .attr("y", function (d) {
          return y.rangeBand() / 2;
        })
        .style("text-anchor", "end")
        .attr("dy", ".35em")
        .transition()
        .duration(chartAttr.animationDuration)
        .attr("x", function (d) {
          return x(d.values[0] * -1) - 5;
        })
        .tween("text", function (d) {
          var i = d3.interpolateRound(0, d.values[0]);
          return function (t) {
            this.textContent = i(t) + "%";
          };
        })
        .delay(chartAttr.animationDelay);

      group
        .append("text")
        .text("0%")
        .attr("class", "bar-text")
        .attr("x", function (d) {
          return x(0) + 5;
        })
        .attr("y", function (d) {
          return y.rangeBand() / 2;
        })
        .style("text-anchor", "start")
        .attr("dy", ".35em")
        .transition()
        .duration(chartAttr.animationDuration)
        .attr("x", function (d) {
          return x(d.values[1]) + 5;
        })
        .tween("text", function (d) {
          var i = d3.interpolateRound(0, d.values[1]);
          return function (t) {
            this.textContent = i(t) + "%";
          };
        })
        .delay(chartAttr.animationDelay);

      /* Draw legend if option is selected */
      var legend = d3
        .select(chartAttr.chartIdSelector + "-legend")
        .attr("height", 50)
        .attr("width", legendWidth)
        .selectAll(".legend")
        .data([chartAttr.gaugeTitle])
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", function (d, i) {
          return "translate(" + (legendWidth + i * 150 - 300) + ", 5)";
        });

      legend
        .append("clipPath")
        .attr("id", "cut-off-bottom")
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 36)
        .attr("height", 18);

      legend
        .append("circle")
        .attr("cx", 18)
        .attr("cy", 18)
        .attr("r", 18)
        .attr("clip-path", "url(#cut-off-bottom)")
        .style("fill", function (d, i) {
          return chartAttr.colorsObj[i];
        });

      legend
        .append("text")
        .attr("x", 42)
        .attr("y", 9)
        .attr("dy", ".35em")
        .attr("class", "legend-text")
        .style("text-anchor", "start")
        .text(function (d) {
          return d;
        });

      return this;
    },
  };

  return exports;
});

