define('app/module',["jquery", "modernizr", "polyfill.requestanimationframe"], function (
  $,
  modernizr
) {
  var resizeFrame,
    modules = $(".module");

  var exports = {
    init: function () {
      exports.setModuleHeight();

      return this;
    },

    setModuleHeight: function () {
      $(window)
        .on("resize", function (e) {
          resizeFrame = window.requestAnimationFrame(exports.resizeHandler);
        })
        .trigger("resize");

      return this;
    },

    resizeHandler: function (e) {
      modules.each(function () {
        var thisWrap = $(".gradient-overlay", this),
          wrapHeight = $(".wrap", this).height(),
          windowHeight = $(window).height();

        thisWrap.height(wrapHeight > windowHeight ? wrapHeight : windowHeight);
      });

      return this;
    },
  };

  return exports;
});

