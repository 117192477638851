define('app/chart/chart-donut',["jquery", "d3", "app/chart/chart-attr", "app/chart/legend"], function (
  $,
  d3,
  ChartAttr,
  Legend
) {
  var exports = {
    init: function ($element) {
      exports.loopThroughSVGs($element);

      return this;
    },

    loopThroughSVGs: function ($element, metaHeading) {
      var i;

      // Collect chart data attributes
      var chartAttr = new ChartAttr($element, {
        default: {
          headingType: "metaHeading",
          animationDuration: 1000,
          margin: 30,
          svgWidth: 160,
        },
      });

      // Turn seperate data arrays into a cohesive object
      var data = chartAttr.createDataObj(metaHeading);

      chartAttr.$thisEl.removeClass("is-not-loaded");

      // Insert meta heading buttons if turned on
      chartAttr.insertHeadingButtons(metaHeading, exports.metaToggleCallback);

      /* Execute Build Chart Function */
      for (i = 0; i < data.length; i++) {
        exports.buildChart(chartAttr, i, metaHeading);
      }

      return this;
    },

    metaToggleCallback: function ($element, metaHeading) {
      // empty existing charts
      $element.find(".chart-legend, svg").empty();
      $element.remove(".chart-donut-label");

      exports.loopThroughSVGs($element, metaHeading);
    },

    buildChart: function (chartAttr, index, metaHeading) {
      // Default grid size and margins
      var subChartEl = $(chartAttr.chartIdSelector + "-" + index);
      var legendWidth = $(chartAttr.chartIdSelector + "-legend").width();

      var dataTempArray = [],
        dataSubSet = chartAttr.dataObj[index],
        dataSubArray = [],
        inverse;

      for (var prop in dataSubSet) {
        if (dataSubSet.hasOwnProperty(prop)) {
          dataTempArray = [];

          if (prop !== chartAttr.attributeTitle) {
            inverse = 100 - dataSubSet[prop];
            dataTempArray.push(dataSubSet[prop]);
            dataTempArray.push(inverse);

            dataSubArray.push(dataTempArray);
          }
        }
      }

      // Width and Height calculations
      var width = chartAttr.svgWidth,
        height =
          dataSubArray.length * (chartAttr.svgWidth + chartAttr.margin) -
          chartAttr.margin,
        radius = width;

      var chartGroup = subChartEl.parent();

      chartGroup.children().remove(".chart-label");
      var chartLabel = $(
        '<div class="chart-label ' + chartAttr.size + '-height">'
      ).prependTo(chartGroup);

      var chartText = $('<div class="chart-donut-text"></div>')
        .appendTo(chartLabel)
        .text(chartAttr.rowsObj[index][0]);

      if (chartAttr.imagesObj[index]) {
        var chartImage = $('<div class="chart-donut-image"></div>').prependTo(
          chartLabel
        );
        chartImage.css({
          "background-image": "url(" + chartAttr.imagesObj[index] + ")",
        });
      }

      // Draw the chart
      var chart = d3
        .select(chartAttr.chartIdSelector + "-" + index)
        .attr("width", width)
        .attr("height", height)
        .classed("not-loaded", false);

      var pie = d3.layout.pie().sort(null);

      var arc = d3.svg
        .arc()
        .outerRadius(radius / 2)
        .innerRadius(radius / 4);

      var tweenPie = function (b) {
        b.innerRadius = 0;
        var i = d3.interpolate(
          {
            startAngle: 0,
            endAngle: 0,
          },
          b
        );
        return function (t) {
          return arc(i(t));
        };
      };

      var colorObj = chartAttr.colorsObj;
      if (chartAttr.showMetaToggle) {
        colorObj = chartAttr.createColorObject(metaHeading);
      }
      // Create as many donuts as there columns

      for (var cnt = 0; cnt < dataSubArray.length; cnt++) {
        chart
          .append("g")
          .attr(
            "transform",
            "translate(" +
              width / 2 +
              "," +
              (width * (cnt + 1) + cnt * chartAttr.margin - radius / 2) +
              ")"
          )
          .attr("class", "donut-" + (cnt + 1));
      }
      /*jshint -W083 */
      for (cnt = 0; cnt < dataSubArray.length; cnt++) {
        var donut = chart
          .select("g.donut-" + (cnt + 1))
          .selectAll("g.slice")
          .data(pie(dataSubArray[cnt]))
          .enter()
          .append("g")
          .attr("class", "slice");

        donut
          .append("circle")
          .attr("class", "center-circle")
          .attr("opacity", function (d, i) {
            return i === 0 ? "1" : "0";
          })
          .attr("r", radius / 4)
          .attr("transform", function (d) {
            return "translate(0, 0)";
          });

        donut
          .append("path")
          .attr("fill", colorObj[cnt])
          .attr("opacity", function (d, i) {
            return i === 0 ? "1" : "0";
          })
          .transition()
          .ease("cubic-in-out")
          .duration(chartAttr.animationDuration)
          .attrTween("d", tweenPie);

        donut
          .append("text")
          .attr("transform", function (d) {
            return "translate(0, 0)";
          })
          .attr("dy", ".35em")
          .style("fill", colorObj[cnt])
          .style("text-anchor", "middle")
          .text("0%")
          .attr("class", "slice-label")
          .attr("opacity", 0)
          .transition()
          .attr("opacity", function (d, i) {
            return i === 0 ? "1" : "0";
          })
          .duration(chartAttr.animationDuration)
          .tween("text", function (d, i) {
            var inter = d3.interpolateRound(0, d.value);
            return function (t) {
              this.textContent = inter(t) + "%";
            };
          })
          .delay(chartAttr.animationDelay);
      }

      /* Draw legend if option is selected */

      $(chartAttr.chartIdSelector).siblings(".legend").remove();

      if (chartAttr.showColumnLegend) {
        if (!metaHeading && chartAttr.showMetaToggle) {
          metaHeading = chartAttr.getFirstMetaheading();
        }
        var data = chartAttr.createDataObj(metaHeading);
        var legendHeadings = chartAttr.showMetaToggle
          ? chartAttr.getHeadingsArray(metaHeading)
          : chartAttr.getMetaHeadingsArray();

        if (chartAttr.showMetaToggle && dataSubArray.length === 1) {
          return this;
        }
        var legendObj = Legend.createData(legendHeadings, colorObj);
        var legend = new Legend($(chartAttr.chartIdSelector), legendObj);
      }

      return this;
    },
  };

  return exports;
});

