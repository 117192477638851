define('app/chart/chart-horizontal-pyramid',["jquery", "d3", "app/chart/chart-attr", "app/chart/legend"], function (
  $,
  d3,
  ChartAttr,
  Legend
) {
  var exports = {
    init: function ($element, resize) {
      var heading = exports.getHeading($element);
      exports.buildChart($element, resize, heading);

      return this;
    },

    buildChart: function ($element, resize, metaHeading) {
      // Collect chart data attributes
      var chartAttr = new ChartAttr($element, {
        default: {
          headingType: "metaHeading",
          margin: { top: 0, right: 150, bottom: 0, left: 150 },
          animationDuration: 1500,
          barPadding: 0,
        },

        mobile: {
          margin: { top: 0, right: 50, bottom: 0, left: 5 },
        },
      });

      // Default grid size and margins
      var yPadding = chartAttr.margin.left;

      // Mobile option changes
      if (chartAttr.isMobile) {
        yPadding = chartAttr.svgWidth;
      }

      // Page resize specific attributes
      chartAttr.cancelAnimations(resize);

      // Insert meta heading buttons if turned on
      chartAttr.insertHeadingButtons(metaHeading, exports.metaToggleCallback);

      // Set data variable
      var data = chartAttr.createDataObj(metaHeading).reverse();

      var keys = d3.keys(data[0]).filter(function (key) {
        return key !== chartAttr.attributeTitle;
      });

      data.forEach(function (d) {
        d.keys = keys.map(function (name) {
          return {
            name: name,
            value: +d[name],
            widthMultiplier: chartAttr.widthMultiplier(name),
          };
        });
      });

      // Width and Height calculations
      var numOfRows = data.length * data[0].keys.length,
        width =
          chartAttr.svgWidth - chartAttr.margin.left - chartAttr.margin.right,
        height =
          75 * numOfRows - chartAttr.margin.top - chartAttr.margin.bottom;

      /* Set up d3 scales */
      var x = d3.scale.linear().rangeRound([0, width]);

      var y0 = d3.scale.ordinal().rangeRoundBands([height, 0], 0.3, 0.1);

      var y1 = d3.scale.ordinal();

      // If toggles are present, create a color object, otherwise use the palette directly.
      var $metaToggles = $element.prev(".chart-toggle");
      var colorObj = chartAttr.colorsObj;
      if ($metaToggles.length > 0) {
        colorObj = chartAttr.createColorObject(metaHeading);
      }

      var color = d3.scale.ordinal().range(colorObj);

      var xAxis = d3.svg.axis().scale(x).orient("bottom");

      var yAxis = d3.svg.axis().scale(y0).orient("left");

      var chart = d3
        .select(chartAttr.chartIdSelector)
        .html("") // Clear SVGs for redraw on resize
        .attr("width", width + chartAttr.margin.left + chartAttr.margin.right)
        .attr("height", height + chartAttr.margin.top + chartAttr.margin.bottom)
        .classed("is-not-loaded", false)
        .append("g")
        .attr(
          "transform",
          "translate(" +
            chartAttr.margin.left +
            "," +
            chartAttr.margin.top +
            ")"
        );

      x.domain([0, 100]);
      y0.domain(
        data.map(function (d) {
          return d[chartAttr.attributeTitle];
        })
      );
      y1.domain(keys).rangeRoundBands([0, y0.rangeBand()]);

      var yAxisText = chart
        .append("g")
        .attr("class", "y axis")
        .attr("transform", "translate(" + chartAttr.margin.left * -1 + ", 0)")
        .call(yAxis)
        .selectAll(".tick text")
        .call(chartAttr.wrapText, yPadding - 10, true)
        .style("text-anchor", "start");

      if (chartAttr.isMobile) {
        yAxisText
          .selectAll("tspan")
          .attr(
            "y",
            "-" +
              (9 +
                keys.length * ((y1.rangeBand() + chartAttr.barPadding * 2) / 2))
          )
          .attr("x", chartAttr.margin.left);
      }

      var group = chart
        .selectAll(".group")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "g")
        .attr("transform", function (d) {
          return (
            "translate(0, " +
            (y0(d[chartAttr.attributeTitle]) - chartAttr.barPadding) +
            ")"
          );
        });

      group
        .selectAll("path")
        .data(function (d) {
          return d.keys;
        })
        .enter()
        .append("path")
        .attr("class", "bar-pyramid")
        .style("fill", function (d) {
          return color(d.name);
        })
        .transition()
        .duration(chartAttr.animationDuration)
        .attrTween("d", function (d) {
          if (!isNaN(d.value)) {
            var i = d3.interpolateRound(0, x(d.value));
            return function (t) {
              var path = "M 0 " + y1(d.name);
              path +=
                "L 0 " +
                (y1(d.name) + (y1.rangeBand() + chartAttr.barPadding * 2));
              path +=
                "L " +
                i(t) +
                " " +
                (y1(d.name) + (y1.rangeBand() / 2 + chartAttr.barPadding));
              path += "L 0 " + y1(d.name);

              return path;
            };
          }
        })
        .delay(chartAttr.animationDelay);

      group
        .selectAll("text")
        .data(function (d) {
          return d.keys;
        })
        .enter()
        .append("text")
        .text(function (d) {
          return "0%";
        })
        .attr("x", 0)
        .attr("y", function (d) {
          return y1(d.name) + chartAttr.barPadding + y1.rangeBand() / 2;
        })
        .attr("dy", ".35em")
        .transition()
        .attr("x", function (d) {
          if (!isNaN(d.value)) {
            return x(d.value) + 10;
          }

          return 0;
        })
        .duration(chartAttr.animationDuration)
        .tween("text", function (d) {
          return chartAttr.notANumberTextHandler(d.value);
        })
        .delay(chartAttr.animationDelay);

      var chartWrap = $(chartAttr.chartIdSelector).closest(".chart-wrap");
      chartWrap.find(".legend").remove();

      /* Draw legend if option is selected */

      if (chartAttr.showColumnLegend) {
        if (!metaHeading && chartAttr.showMetaToggle) {
          metaHeading = chartAttr.getFirstMetaheading();
        }
        data = chartAttr.createDataObj(metaHeading);
        var legendHeadings = chartAttr.showMetaToggle
          ? chartAttr.getHeadingsArray(metaHeading)
          : chartAttr.getHeadingsArray();

        var legendObj = Legend.createData(legendHeadings, colorObj);
        var legend = new Legend($(chartAttr.chartIdSelector), legendObj);
      }
      // var legend = chart.selectAll(".legend")
      // 		.data(color.domain().slice())
      // 	.enter().append("g")
      // 		.attr("class", "legend")
      // 		.attr("transform", function(d, i) {
      // 			var translate = i * 25 + chartAttr.barPadding * 2;

      // 			if (chartAttr.isMobile) {
      // 				translate -= chartAttr.margin.top + 5;
      // 			}

      // 			return "translate(0," + translate + ")";
      // 		});

      // legend.append("rect")
      // 		.attr("x", width + chartAttr.margin.right - 20) .attr("width", 18)
      // 		.attr("height", 18)
      // 		.attr("class", "legend-color")
      // 		.style("fill", color);

      // legend.append("text")
      // 		.attr("x", width + chartAttr.margin.right - 26) .attr("y", 9)
      // 		.attr("dy", ".35em")
      // 		.attr("class", "legend-text")
      // 		.style("text-anchor", "end")
      // 		.text(function(d) { return d; });

      return this;
    },

    metaToggleCallback: function ($element, metaHeading) {
      $element.empty();
      exports.buildChart($element, false, metaHeading);
    },

    getHeading: function ($element) {
      var heading = null;
      var chartAttr = $element.data("chartAttr");
      if (chartAttr && chartAttr.showMetaToggle) {
        heading = $("#js-heading-toggle-" + chartAttr.chartId)
          .find(".chart-toggle-item.active")
          .text();
      }
      return heading;
    },
  };

  return exports;
});

