define('app/contact-form',["jquery"], function ($) {
  var exports = {
    init: function () {
      $(".contact-form .submit-button").on("click", exports.submitForm);

      return this;
    },

    submitForm: function (e) {
      e.preventDefault();
      var form = $(e.currentTarget).closest("form");
      $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: form.serialize(),
        dataType: "json",
        beforeSend: function () {
          form.find(".errors").remove();
          form.css("opacity", "0.5");
        },
        success: function (data) {
          var input, errors, recaptchaIframeEl;
          form.css("opacity", "1");
          form.find(".errors").remove();
          if (data.error) {
            recaptchaIframeEl = form.find("iframe");
            recaptchaIframeEl[0].src = recaptchaIframeEl[0].src; // refresh recaptcha on error
            for (var key in data.error) {
              if (data.error.hasOwnProperty(key)) {
                if (key === "recaptcha") {
                  input = form.find(".g-recaptcha");
                } else {
                  input = form.find("input#" + key + ",textarea#" + key);
                }
                errors = $('<ul class="errors"></ul>').insertAfter(input);
                for (i = 0; i < data.error[key].length; i++) {
                  $(
                    '<li class="error">' + data.error[key][i] + "</li>"
                  ).appendTo(errors);
                }
              }
            }
          } else {
            form
              .closest(".entry-form")
              .replaceWith(
                "<div class='contact-thank-you'>Thanks for your message! We'll be in touch shortly.</div>"
              );
          }
        },
        error: function (xhr, err) {
          form.css("opacity", "1");
          alert(
            "We were unable to submit your contact information.  Please try again later."
          );
        },
      });
    },
  };

  return exports;
});

