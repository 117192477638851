define('app/scroll-arrow',["jquery", "jquery.scrollTo", "jquery.easing"], function ($) {
  var scrollArrow = $(".js-scroll-arrow");

  var exports = {
    init: function () {
      exports.scrollToNextSection();

      return this;
    },

    scrollToNextSection: function () {
      scrollArrow.on("click", function () {
        var parentSection = $(this).closest("section");
        var nextId = "#" + parentSection.next("section").attr("id");

        if (nextId.length) {
          $.scrollTo($(nextId), 500, {
            easing: "easeInOutCubic",
          });

          return false;
        }
      });

      return this;
    },
  };

  return exports;
});

