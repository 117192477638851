define('app/chart/chart-line',["jquery", "d3", "app/chart/chart-attr"], function ($, d3, ChartAttr) {
  var exports = {
    init: function ($element, resize) {
      var heading = exports.getHeading($element);
      exports.buildChart($element, resize, heading);

      return this;
    },

    buildChart: function ($element, resize, metaHeading) {
      // Collect chart data attributes
      var chartAttr = new ChartAttr($element, {
        default: {
          headingType: "metaHeading",
          margin: { top: 60, right: 30, bottom: 75, left: 60 },
          heightMultiplier: 0.6,
          animationDuration: 1000,
          legendPerRow: $element.data("long-legend") ? 2 : 4, // For legacy tab
          legendColTotalWidth: 600,
        },

        mobile: {
          margin: { top: 100, right: 5, bottom: 5, left: 5 },
          heightMultiplier: 1,
        },
      });

      // Page resize specific attributes
      chartAttr.cancelAnimations(resize);

      // Insert meta heading buttons if turned on
      chartAttr.insertHeadingButtons(metaHeading, exports.metaToggleCallback);

      // Set data variable
      var data = chartAttr.createDataObj(metaHeading);

      // Width and Height calculations
      var width =
          chartAttr.svgWidth - chartAttr.margin.left - chartAttr.margin.right,
        height =
          chartAttr.svgWidth * chartAttr.heightMultiplier -
          chartAttr.margin.top -
          chartAttr.margin.bottom;

      // Set up d3 scales
      var x = d3.scale.ordinal().rangeRoundBands([0, width]);

      var y = d3.scale.linear().range([height, 0]);

      // If toggles are present, create a color object, otherwise use the palette directly.
      var $metaToggles = $element.prev(".chart-toggle");
      var colorObj = chartAttr.colorsObj;
      if ($metaToggles.length > 0) {
        colorObj = chartAttr.createColorObject(metaHeading);
      }

      var color = d3.scale.ordinal().range(colorObj);

      var xAxis = d3.svg.axis().scale(x).orient("bottom");

      var yAxis = d3.svg.axis().scale(y).orient("right");

      var line = d3.svg
        .line()
        .x(function (d) {
          return x(d.attribute) + x.rangeBand() / 2;
        })
        .y(function (d) {
          return y(d.value);
        });

      if (chartAttr.showGrid) {
        yAxis.tickSize(width);
      }

      // Select SVG and set height and width
      var chart = d3
        .select(chartAttr.chartIdSelector)
        .html("") // Clear SVGs for redraw on resize
        .attr("width", width + chartAttr.margin.left + chartAttr.margin.right)
        .attr("height", height + chartAttr.margin.top + chartAttr.margin.bottom)
        .classed("is-not-loaded", false)
        .append("g")
        .attr(
          "transform",
          "translate(" +
            chartAttr.margin.left +
            "," +
            chartAttr.margin.top +
            ")"
        );

      var keys = d3.keys(data[0]).filter(function (key) {
        return key !== chartAttr.attributeTitle;
      });

      var dataProcessed = keys.map(function (key, index) {
        return {
          name: key,
          values: data.map(function (d) {
            return {
              attribute: d[chartAttr.attributeTitle],
              value: +d[key],
              color: colorObj[index],
            };
          }),
        };
      });

      x.domain(
        data.map(function (d) {
          return d[chartAttr.attributeTitle];
        })
      );
      y.domain([0, 100]);

      /* Draw yAxis */
      chart.append("g").attr("class", "y axis").call(yAxis);

      chart
        .select(".y.axis")
        .selectAll(".tick")
        .select("text")
        .attr("x", -10)
        .attr("dy", 3)
        .style("text-anchor", "end")
        .text(function (d) {
          return d;
        });

      chart
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis)
        .selectAll(".tick text")
        .call(chartAttr.wrapText, x.rangeBand() - 10);

      var lines = chart
        .selectAll(".line")
        .data(dataProcessed)
        .enter()
        .append("g")
        .attr("class", "line");

      lines
        .append("path")
        .attr("class", "line")
        .attr("opacity", 0)
        .attr("d", function (d) {
          return line(d.values);
        })
        .transition()
        .duration(chartAttr.animationDuration)
        .attr("opacity", 1)
        .delay(chartAttr.animationDelay);

      // Add circles at each data point
      var points = lines
        .selectAll(".point")
        .data(function (d) {
          return d.values;
        })
        .enter()
        .append("circle")
        .attr("opacity", 0)
        .attr("r", 6)
        .attr("class", "point")
        .style("fill", function (d) {
          return d.color;
        })
        .attr("transform", function (d) {
          return (
            "translate(" +
            (x(d.attribute) + x.rangeBand() / 2) +
            ", " +
            y(d.value) +
            ")"
          );
        })
        .on("mousedown", function (d) {
          chartAttr.$thisEl.next().html(d.attribute);
        })
        .transition()
        .duration(chartAttr.animationDuration)
        .attr("opacity", 1)
        .delay(chartAttr.animationDelay);

      /* Draw legend if option is selected */
      if (chartAttr.showColumnLegend) {
        var legend = chart
          .selectAll(".legend")
          .data(keys)
          .enter()
          .append("g")
          .attr("class", "legend")
          .attr("transform", function (d, i) {
            if (chartAttr.isMobile) {
              var translate = i * -25 - 25;

              return "translate(0," + translate + ")";
            } else {
              var legendWidth =
                chartAttr.legendColTotalWidth / chartAttr.legendPerRow;

              var negOffset = Math.ceil((i + 1) / chartAttr.legendPerRow) * -26;
              return (
                "translate(" +
                (width -
                  (i % chartAttr.legendPerRow) * legendWidth -
                  chartAttr.margin.right) +
                ", " +
                negOffset +
                ")"
              );
            }
          });

        legend
          .append("rect")
          .attr("x", function (d) {
            if (chartAttr.isMobile) {
              return width - 20;
            } else {
              return 0;
            }
          })
          .attr("width", 18)
          .attr("height", 18)
          .attr("class", "legend-color")
          .style("fill", color);

        legend
          .append("text")
          .attr("x", function (d) {
            if (chartAttr.isMobile) {
              return width - 26;
            } else {
              return -6;
            }
          })
          .attr("y", 9)
          .attr("dy", ".35em")
          .attr("class", "legend-text")
          .style("text-anchor", "end")
          .text(function (d) {
            return d;
          });
      }

      // Need to put in this chart function so it can access data.length
      function pathTween() {
        var interpolate = d3.scale
          .quantile()
          .domain([0, 1])
          .range(d3.range(1, data.length + 1));
        return function (t) {
          return line(dataProcessed.values.slice(0, interpolate(t)));
        };
      }

      return this;
    },

    metaToggleCallback: function ($element, metaHeading) {
      $element.empty();
      exports.buildChart($element, false, metaHeading);
    },

    getHeading: function ($element) {
      var heading = null;
      var chartAttr = $element.data("chartAttr");
      if (chartAttr && chartAttr.showMetaToggle) {
        heading = $("#js-heading-toggle-" + chartAttr.chartId)
          .find(".chart-toggle-item.active")
          .text();
      }
      return heading;
    },
  };

  return exports;
});

