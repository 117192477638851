define('app/chart/chart-vertical-bar',["jquery", "d3", "app/chart/chart-attr"], function ($, d3, ChartAttr) {
  var exports = {
    init: function ($element, resize) {
      var heading = exports.getHeading($element);
      exports.buildChart($element, resize, heading);

      return this;
    },

    buildChart: function ($element, resize, metaHeading) {
      // Set up new chart attributes object
      var chartAttr = new ChartAttr($element, {
        default: {
          headingType: "metaHeading",
          margin: { top: 35, right: 30, bottom: 0, left: 60 },
          heightMultiplier: 0.5,
          legendPerRow: $element.data("long-legend") ? 2 : 4, // For legacy tab
          legendColTotalWidth: 600,
        },

        mobile: {
          margin: { top: 35, right: 5, bottom: 5, left: 5 },
          heightMultiplier: 1,
          barPadding: 2,
        },
      });

      // Page resize specific attributes
      chartAttr.cancelAnimations(resize);

      // Insert meta heading buttons if turned on
      chartAttr.insertHeadingButtons(metaHeading, exports.metaToggleCallback);

      // Set data variable

      // First time through, with metaHeading not set, it returns
      // an array with the number of elements equal to the rows in
      // the data table.  It includes the Attribute and the Total
      // column.
      var data = chartAttr.createDataObj(metaHeading);
      // console.log('data: ', data);

      var keys = d3.keys(data[0]).filter(function (key) {
        return key !== chartAttr.attributeTitle;
      });

      data.forEach(function (d) {
        d.keys = keys.map(function (name) {
          return {
            name: name,
            value: +d[name],
            widthMultiplier: chartAttr.widthMultiplier(name),
          };
        });
      });

      var legendOffset = chartAttr.getLegendOffset(data);

      // Width and Height calculations
      var width =
          chartAttr.svgWidth - chartAttr.margin.left - chartAttr.margin.right,
        height =
          chartAttr.svgWidth * chartAttr.heightMultiplier +
          legendOffset -
          chartAttr.margin.top -
          chartAttr.margin.bottom;

      // Set up d3 scales
      var x0 = d3.scale
        .ordinal()
        .rangeRoundBands([0, width], chartAttr.barGroupPadding);

      var x1 = d3.scale.ordinal();

      var y = d3.scale.linear().range([height, 0]);

      // If toggles are present, create a color object, otherwise use the palette directly.
      var $metaToggles = $element.prev(".chart-toggle");
      var colorObj = chartAttr.colorsObj;
      if ($metaToggles.length > 0) {
        colorObj = chartAttr.createColorObject(metaHeading);
      }
      var color = d3.scale.ordinal().range(colorObj);

      var xAxis = d3.svg.axis().scale(x0).orient("bottom");

      var yAxis = d3.svg.axis().scale(y).orient("right");

      if (chartAttr.showGrid) {
        yAxis.tickSize(width);
      }

      // Select SVG
      var chartSVG = d3.select(chartAttr.chartIdSelector);

      // Set height and width
      var chart = chartSVG
        .html("") // Clear SVGs for redraw on resize
        .attr("width", width + chartAttr.margin.left + chartAttr.margin.right)
        .attr("height", height + chartAttr.margin.top + chartAttr.margin.bottom)
        .classed("is-not-loaded", false)
        .append("g")
        .attr(
          "transform",
          "translate(" +
            chartAttr.margin.left +
            "," +
            chartAttr.margin.top +
            ")"
        );

      x0.domain(
        data.map(function (d) {
          return d[chartAttr.attributeTitle];
        })
      );
      x1.domain(keys).rangeRoundBands([0, x0.rangeBand()]);
      y.domain([0, 100]);

      // Draw yAxis
      chart
        .append("g")
        .attr("class", "y axis")
        .call(yAxis)
        .selectAll(".tick text")
        .attr("x", -10)
        .attr("dy", 3)
        .style("text-anchor", "end")
        .text(function (d) {
          return d + "%";
        });

      /* Create groups for bars */
      var barGroup = chart
        .selectAll(".bar-group")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "bar-group")
        .attr("transform", function (d) {
          return "translate(" + x0(d[chartAttr.attributeTitle]) + ",0)";
        })
        .on("mousedown", function (d) {
          chartAttr.$thisEl.next().html(d[chartAttr.attributeTitle]);
        });

      /* Create groups for bars and text & draw bars */
      barGroup
        .selectAll("rect")
        .data(function (d) {
          return d.keys;
        })
        .enter()
        .append("g")
        .attr("class", "bar-g")
        .attr("transform", function (d) {
          return "translate(" + x1(d.name) + ",0)";
        })
        .append("rect")
        .attr("width", function (d) {
          var width = x1.rangeBand() * d.widthMultiplier - chartAttr.barPadding;

          return width;
        })
        .attr("x", function (d) {
          var width = x1.rangeBand() * d.widthMultiplier;
          var difference = x1.rangeBand() - width;
          var offset = difference / 2;

          return offset + chartAttr.barPadding / 2;
        })
        .attr("class", "bar-rect")
        .attr("height", 0)
        .attr("y", height)
        .style("fill", function (d) {
          return color(d.name);
        })
        .transition()
        .attr("height", function (d) {
          if (!isNaN(d.value)) {
            return height - y(d.value);
          }

          return height - y(0);
        })
        .attr("y", function (d) {
          if (!isNaN(d.value)) {
            return y(d.value);
          }

          return y(0);
        })
        .duration(chartAttr.animationDuration)
        .delay(chartAttr.animationDelay);

      var barTextRect = chart
        .selectAll(".bar-g")
        .filter(function (d) {
          return d.value > 96;
        })
        .append("rect")
        .attr("height", 18)
        .attr("width", 34)
        .attr("y", function (d) {
          if (!isNaN(d.value)) {
            return y(d.value) - 22;
          }

          return y(0) - 22;
        })
        .attr("x", function (d) {
          var width = x1.rangeBand() / 2 - 17;

          return width;
        })
        .style("fill", "#FFF")
        .attr("opacity", 0)
        .transition()
        .duration(chartAttr.animationDuration)
        .attr("opacity", 1)
        .delay(chartAttr.animationDelay);

      /* Add text labels to bars */
      var barText = chart
        .selectAll(".bar-g")
        .append("text")
        .attr("x", x1.rangeBand() / 2)
        .attr("y", height - 20)
        .attr("dy", ".75em")
        .attr("class", "bar-label")
        .style("text-anchor", "middle")
        .text(function (d) {
          "0%";
        })
        .transition()
        .duration(chartAttr.animationDuration)
        .attr("y", function (d) {
          if (!isNaN(d.value)) {
            return y(d.value) - 20;
          }

          return height - 20;
        })
        .tween("text", function (d) {
          return chartAttr.notANumberTextHandler(d.value);
        })
        .delay(chartAttr.animationDelay);

      /* Draw xAxis so it is over bars (cleaner look) */
      var drawnXAxis = chart
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis)
        .selectAll(".tick text")
        .call(chartAttr.wrapText, x0.rangeBand() - 10);

      // Adjust chart height to account for largest axis label
      if (!chartAttr.isMobile) {
        var largestAxisLabel = chartAttr.largestAxisLabel(drawnXAxis),
          tickSize = 6,
          dataBtnMargin = 25;

        chartAttr.margin.bottom =
          largestAxisLabel * chartAttr.lineHeight + tickSize + dataBtnMargin;

        var chartHeightAdjust = chartSVG.attr(
          "height",
          height + chartAttr.margin.top + chartAttr.margin.bottom
        );
      }

      /* Draw legend if option is selected */
      if (chartAttr.showColumnLegend) {
        var legend = chart
          .selectAll(".legend")
          .data(keys.slice().reverse())
          .enter()
          .append("g")
          .attr("class", "legend")
          .attr("transform", function (d, i) {
            if (chartAttr.isMobile) {
              var translate = i * -25 - 25;
              return "translate(0," + translate + ")";
            } else {
              var legendWidth =
                chartAttr.legendColTotalWidth / chartAttr.legendPerRow;

              var negOffset = Math.ceil((i + 1) / chartAttr.legendPerRow) * -26;
              return (
                "translate(" +
                (width -
                  (i % chartAttr.legendPerRow) * legendWidth -
                  chartAttr.margin.right) +
                ", " +
                negOffset +
                ")"
              );
            }
          });

        legend
          .append("rect")
          .attr("x", function (d) {
            if (chartAttr.isMobile) {
              return width - 20;
            } else {
              return 0;
            }
          })
          .attr("width", 18)
          .attr("height", 18)
          .attr("class", "legend-color")
          .style("fill", color);

        legend
          .append("text")
          .attr("x", function (d) {
            if (chartAttr.isMobile) {
              return width - 26;
            } else {
              return -6;
            }
          })
          .attr("y", 9)
          .attr("dy", ".35em")
          .attr("class", "legend-text")
          .style("text-anchor", "end")
          .text(function (d) {
            return d;
          });
      }

      return this;
    },

    metaToggleCallback: function ($element, metaHeading) {
      $element.empty();
      exports.buildChart($element, false, metaHeading);
    },

    getHeading: function ($element) {
      var heading = null;
      var chartAttr = $element.data("chartAttr");
      if (chartAttr && chartAttr.showMetaToggle) {
        heading = $("#js-heading-toggle-" + chartAttr.chartId)
          .find(".chart-toggle-item.active")
          .text();
      }
      return heading;
    },
  };

  return exports;
});

