define('app/chart/charts',[
  "jquery",
  "d3",
  "app/chart/v2/chart-bubble",
  "app/chart/chart-css",
  "app/chart/chart-donut",
  "app/chart/chart-gauge",
  "app/chart/chart-horizontal-bar",
  "app/chart/v2/chart-horizontal-bar-stacked",
  "app/chart/v2/chart-horizontal-bar-standard",
  "app/chart/chart-horizontal-pyramid",
  "app/chart/chart-image",
  "app/chart/chart-line",
  "app/chart/chart-pie",
  "app/chart/chart-vertical-bar",
  "app/chart/chart-vertical-pyramid",
  "app/chart/v2/chart",
], function (
  $,
  d3,
  ChartBubble,
  chartCSS,
  chartDonut,
  chartGauge,
  chartHorizontalBar,
  ChartHorizontalBarStacked,
  ChartHorizontalBarStandard,
  chartHorizontalPyramid,
  chartImage,
  chartLine,
  chartPie,
  chartVerticalBar,
  chartVerticalPyramid,
  Chart
) {
  var $windowEl = $(window),
    timeoutID,
    charts = [];

  var exports = {
    init: function () {
      $(".js-chart").each(function () {
        var $element = $(this),
          d3Element = d3.select(this), // jQuery can't grab classes on SVGs
          chart;

        // Single SVG Charts

        if (d3Element.classed("chart-bubble")) {
          chart = new ChartBubble($element);
          exports.scrollCache(chart, $element, true);
        }

        if (d3Element.classed("chart-horizontal-bar-stacked")) {
          chart = new ChartHorizontalBarStacked($element);
          exports.scrollCache(chart, $element, true);
        }

        if (d3Element.classed("chart-horizontal-bar-standard")) {
          chart = new ChartHorizontalBarStandard($element);
          exports.scrollCache(chart, $element, true);
        }

        if (d3Element.classed("chart-horizontal-pyramid")) {
          exports.scrollCache(chartHorizontalPyramid, $element, true);
        }

        if (d3Element.classed("chart-line")) {
          exports.scrollCache(chartLine, $element, true);
        }

        if (d3Element.classed("chart-vertical-bar")) {
          exports.scrollCache(chartVerticalBar, $element, true);
        }

        if (d3Element.classed("chart-vertical-pyramid")) {
          exports.scrollCache(chartVerticalPyramid, $element, true);
        }

        // Multiple SVG Charts

        if (d3Element.classed("chart-donut")) {
          exports.scrollCache(chartDonut, $element);
        }

        if (d3Element.classed("chart-gauge")) {
          exports.scrollCache(chartGauge, $element);
        }

        if (d3Element.classed("chart-horizontal-bar")) {
          exports.scrollCache(chartHorizontalBar, $element);
        }

        if (d3Element.classed("chart-pie")) {
          exports.scrollCache(chartPie, $element, true);
        }

        // CSS based charts

        if (d3Element.classed("chart-css")) {
          exports.scrollCache(chartCSS, $element);
        }

        if (d3Element.classed("chart-image")) {
          exports.scrollCache(chartImage, $element);
        }
      });

      // If param ?data=1 in the url, we want to scroll to first chart
      timeoutID = window.setTimeout(function () {
        var queryParams = exports.getQueryParams(document.location.search);
        if ("data" in queryParams) {
          if ($(".chart").length) {
            $("html, body").animate(
              {
                scrollTop: $(".chart").offset().top - 55,
              },
              1500
            );
          }
        }
      }, 200);

      return this;
    },

    getQueryParams: function (qs) {
      qs = qs.split("+").join(" ");

      var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

      tokens = re.exec(qs);
      while (tokens) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        tokens = re.exec(qs);
      }

      return params;
    },

    scrollCache: function (callback, $element, enableResize) {
      var isLoaded = false,
        topOffset,
        bottomOffset,
        height,
        scroll;

      // callback.init($element, true);

      $windowEl
        .on("scroll", function () {
          scroll = $windowEl.scrollTop() + $windowEl.height() / 2;
          height = $element.outerHeight();
          topOffset = $element.offset().top;
          bottomOffset = topOffset + height;

          // If chart hasn't been loaded yet
          if (!isLoaded && scroll > topOffset && scroll < bottomOffset) {
            isLoaded = true;

            // Initial load with animations
            if (callback instanceof Chart) {
              // v2 Chart
              callback.show();
            } else {
              // v1 Chart
              callback.init($element, false);
            }

            // Resize cache without animations
            if (enableResize) {
              // (callback.init functions duplicate for performance reasons)
              $windowEl.on("resize", function (e) {
                if (callback.init) {
                  callback.init($element, true);
                } else {
                  callback.animate = false;
                  callback.show();
                }
              });
            }
          }
        })
        .trigger("scroll");

      // Load charts without animations if print button is pushed
      $windowEl.on("printCharts", function () {
        if (callback.init) {
          callback.init($element, true);
        } else {
          callback.show();
        }
      });

      return this;
    },
  };

  return exports;
});

