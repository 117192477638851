define('app/chart/v2/chart-horizontal-bar-standard',["jquery", "d3", "app/chart/v2/chart"], function ($, d3, Chart) {
  var HorizontalBarStandardChart = function ($element, data, opts) {
    this.options = {
      headingType: "metaHeading",
      showMetaToggle: true,
      useSameColorsPerMeta: false,
      showColumnLegend: true,
      labelWidth: 150,
      minBarHeight: 17,
      optimalBarHeight: 43,

      default: {
        // margin: { top: 10, right: 150, bottom: 20, left: 150 },
        margin: { top: 0, right: 50, bottom: 0, left: 0 },
        rowSpacing: 35,
        barPadding: 0.125, // as a percentage of the bar
      },

      mobile: {
        margin: { top: 0, right: 0, bottom: 0, left: 5 },
        // margin: { top: 10, right: 5, bottom: 0, left: 5 },
        rowSpacing: 20,
        barPadding: 0.2,
      },
    };

    // Call the super-constructor
    Chart.call(this, $element, data, opts);

    return this;
  };

  // HorizontalBarStandardChart class extends the base Chart class.
  HorizontalBarStandardChart.prototype = Object.create(Chart.prototype);

  HorizontalBarStandardChart.prototype.draw = function () {
    var chartData = this.chartData;
    var my = this;
    var margins = this.getMargins();
    var data = this.data;
    var xBegin;
    var svgWidth = this.getSvgWidth();
    var barPadding, rowSpacing;
    var optimalBarHeight = this.options.optimalBarHeight;
    var minBarHeight = this.options.minBarHeight;

    // Set labelWidth based on screen size for bar width
    var labelWidth = this.isMobile() ? svgWidth : this.options.labelWidth;

    var rowHeaders = this.getKeys();

    var numRows = data.length;
    // console.log('numRows', numRows);

    // Mobile attribute changes
    if (this.isMobile()) {
      barPadding = this.options.mobile.barPadding;
      rowSpacing = this.options.mobile.rowSpacing;
    } else {
      barPadding = this.options.default.barPadding;
      rowSpacing = this.options.default.rowSpacing;
    }

    /* There are three things we need to know before we can lay out 
		   and size our chart:

		   1) the height of the largest y text label
		   2) the maximum number of bars in all meta-categories 
		   3) the minimum number of bars in all meta-categories

		   The last two are, of course, only relevant if the meta-toggle is on.
		*/

    // First, let's find the biggest label

    // Width and Height calculations (arbitrary)
    var width = svgWidth,
      height = 38 * numRows;

    // Note that these calculations will be re-done after laying out labels
    // That is, look for height calculations further down...

    // over-ride existing height, which is set in chart.js

    d3.select(this.chartIdSelector).attr("height", height);

    // Draw the chart
    var x = d3.scale
      .linear()
      .rangeRound([
        0,
        width - (margins.right + (this.isMobile() ? 0 : labelWidth)),
      ]);

    var y0 = d3.scale.ordinal().rangeRoundBands([0, height]);

    var y1 = d3.scale.ordinal();

    var xAxis = d3.svg.axis().scale(x).orient("bottom");

    var yAxis = d3.svg.axis().scale(y0).orient("left");

    var chart = this.chart.select(".visual");

    x.domain([0, 100]);
    y0.domain(
      data.map(function (d) {
        return d.attribute;
      })
    );
    // console.log('getKeys', this.getKeys());
    y1.domain(this.getKeys()).rangeRoundBands([0, y0.rangeBand()], barPadding);

    chart
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    var yAxisText = chart
      .append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .selectAll(".tick text")
      .call(this.wrapText, labelWidth - 10)
      .style("text-anchor", "start");

    if (this.isMobile()) {
      yAxisText
        .selectAll("tspan")
        .attr("y", y0.rangeBand() / 2 + 10)
        .attr("x", margins.left);
    }

    var textMaxHeight;
    if (this.isMobile()) {
      textMaxHeight = 17;
    } else {
      textMaxHeight = 39;
    }
    $(".y.axis .tick text").each(function (idx, el) {
      var bbox = d3.select(el).node().getBBox();
      textMaxHeight = Math.max(bbox.height, textMaxHeight);
    });

    // We now have 1) the textMaxHeight
    // Let's get 2) and 3):

    var minNumBars = this.chartData.getMinNumColumns();
    var maxNumBars = this.chartData.getMaxNumColumns();

    // Now we have our pre-requisites, we can fully lay out the chart

    chart.html(""); // clear out chart to start over

    var barsPerRow = 0;
    if (data.length) {
      barsPerRow = data[0].columns.length;
    }
    // If there's only one bar per row, give it less padding
    if (barsPerRow === 1) {
      rowSpacing /= 2;
    }

    var rowHeight, barGroupHeight, barHeight;
    if (this.isMobile()) {
      // console.log('textMaxHeight', textMaxHeight);
      // console.log('barsPerRow * minBarHeight', barsPerRow * minBarHeight);
      // console.log('barsPerRow', barsPerRow, 'minBarHeight', minBarHeight);
      // console.log('(barsPerRow * minBarHeight) / (1 - barPadding)', (barsPerRow * minBarHeight) / (1 - barPadding));
      if (barsPerRow > 1) {
        rowHeight =
          textMaxHeight + (barsPerRow * minBarHeight) / (1 - barPadding);
      } else {
        rowHeight = textMaxHeight + barsPerRow * minBarHeight;
      }
      barHeight = minBarHeight;
      barGroupHeight = rowHeight - textMaxHeight;
    } else {
      var optimalBarGroupHeight =
        (minNumBars * optimalBarHeight) / (1 - barPadding);
      optimalBarGroupHeight = Math.max(
        optimalBarGroupHeight,
        textMaxHeight - 10
      );
      // console.log('optimalBarHeight', optimalBarHeight);
      // console.log('optimalBarGroupHeight', optimalBarGroupHeight);
      if (barsPerRow > minNumBars) {
        var minBarGroupHeight = barsPerRow * minBarHeight;
        // console.log('minBarGroupHeight', minBarGroupHeight);
        if (minBarGroupHeight > optimalBarGroupHeight) {
          barHeight = minBarHeight;
          barGroupHeight = minBarGroupHeight;
        } else {
          var availableSpace = optimalBarGroupHeight - minBarGroupHeight;
          var calculatedPadding = minBarGroupHeight * barPadding;
        }
        barGroupHeight = Math.max(optimalBarGroupHeight, minBarGroupHeight);
        // console.log('barGroupHeight', barGroupHeight);
      } else {
        barGroupHeight = optimalBarGroupHeight;
        barHeight = optimalBarHeight;
      }
      rowHeight = Math.max(textMaxHeight, barGroupHeight);
    }
    // console.log('rowHeight', rowHeight);
    var combinedRowHeight = numRows * rowHeight;
    var combinedSpaceHeight = (numRows - 1) * rowSpacing;
    // console.log('combinedRowHeight', combinedRowHeight);
    // console.log('combinedSpaceHeight', combinedSpaceHeight);
    height = combinedRowHeight + combinedSpaceHeight;
    var spaceRowRatio = rowSpacing / (rowHeight + rowSpacing);
    // console.log('spaceRowRatio', spaceRowRatio);
    // console.log('calculated height', height);

    d3.select(this.chartIdSelector).attr("height", height);

    y0 = d3.scale.ordinal();

    yAxis = d3.svg.axis().scale(y0).orient("left");

    y0.domain(
      data.map(function (d) {
        return d.attribute;
      })
    );
    y0.rangeRoundBands([0, height], spaceRowRatio, 0);
    y1.domain(this.getKeys()).rangeRoundBands(
      [0, barGroupHeight],
      barPadding,
      0
    );
    // console.log('barGroupHeight', barGroupHeight);
    // console.log('rowSpacing', rowSpacing);
    // console.log('y0.domain', y0.domain());
    // console.log('y1.domain', y1.domain());
    // console.log('y0.range', y0.range());
    // console.log('y0.rangeBand', y0.rangeBand());
    // console.log('y1.range', y1.range());
    // console.log('y1.rangeBand', y1.rangeBand());
    // console.log('x.range', x.range());

    chart
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    yAxisText = chart
      .append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .selectAll(".tick text")
      .call(this.wrapText, labelWidth - 10)
      .style("text-anchor", "start");

    if (this.isMobile()) {
      yAxisText
        .selectAll("tspan")
        .attr("y", barGroupHeight - y0.rangeBand() / 2 + 10)
        .attr("x", 0);

      // hide tick mark
      chart.selectAll(".y.axis .tick line").attr("x2", 0);
    }

    // Moving on now...

    var barGroupOffset = (y0.rangeBand() - barGroupHeight) / 2; // center the bar group
    // console.log('barGroupOffset', barGroupOffset);
    var group = chart
      .selectAll(".group")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "g")
      .attr("transform", function (d) {
        return (
          "translate(" +
          (my.isMobile() ? "0" : labelWidth) +
          ", " +
          (y0(d.attribute) + (my.isMobile() ? 0 : barGroupOffset)) +
          ")"
        );
      });

    // console.log('data', data);
    // console.log('y1.range()', y1.range());
    // console.log('barsPerRow', barsPerRow);
    // console.log('bar height', y1.rangeBand());
    group
      .selectAll("rect")
      .data(function (d) {
        return d.columns;
      })
      .enter()
      .append("rect")
      .attr("class", "bar-rect")
      .attr("height", function (d) {
        // return Math.floor(bandsWidth / barsPerRow);
        return y1.rangeBand();
      })
      .attr("x", 0)
      .attr("y", function (d) {
        return y1(d.label);
      })
      // .attr("y", function(d) { return y1(0) + barPadding / 2; })
      .attr("width", 0)
      .style("fill", function (d) {
        return d.color;
      });

    if (this.animate) {
      group
        .selectAll("rect")
        .transition()
        .attr("width", function (d) {
          if (!isNaN(d.value)) {
            return x(d.value);
          }

          return 0;
        })
        .duration(my.animationDuration)
        .delay(my.animationDelay);
    } else {
      group.selectAll("rect").attr("width", function (d) {
        if (!isNaN(d.value)) {
          return x(d.value);
        }

        return 0;
      });
    }

    group
      .selectAll("text")
      .data(function (d) {
        return d.columns;
      })
      .enter()
      .append("text")
      .attr("x", 10)
      .attr("y", function (d) {
        return y1(d.label) + y1.rangeBand() / 2;
      })
      .attr("dy", ".35em");

    if (this.animate) {
      group
        .selectAll("text")
        .text("0%")
        .transition()
        .duration(my.animationDuration)
        .tween("text", function (d) {
          return my.notANumberTextHandler(d.value);
        })
        .attr("x", function (d) {
          if (!isNaN(d.value)) {
            return x(d.value) + 10;
          }
          return 5;
        })
        .delay(my.animationDelay);
    } else {
      group
        .selectAll("text")
        .text(function (d) {
          if (!isNaN(d.value)) {
            return d.value + "%";
          } else {
            return "N/A";
          }
        })
        .attr("x", function (d) {
          if (!isNaN(d.value)) {
            return x(d.value) + 10;
          }
          return 5;
        });
    }

    if (!this.isMobile()) {
      this.adjustYLabelsVertically(-7);
    }
  };

  return HorizontalBarStandardChart;
});

